import React, {useEffect, useState} from 'react';
import {
    Box, Checkbox, FormControlLabel,
    Grid, Stack,
    TextField,
} from "@mui/material";
import {
    TabBlock,
} from "../style";
import Typography from "@mui/material/Typography";
import {CodeBlock, dracula} from "react-code-blocks";
import ButtonBlue from "Blocks/ButtonBlue/ButtonBlue";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {useStore} from "stores";

type TabWidgetT = {
    tab: number,
};

export const TabWidget = ({tab}: TabWidgetT) => {
    const {t} = useTranslation('common');
    const {userStore: {data: user, updateSettingsApi}} = useStore();

    const code = `<script src="${process.env.REACT_APP_FRONT_URL}/plugin.js"></script>\n<script>\nHelpPlugin('${user?.application.token}');\n</script>`;
    const [welcomeMessageUa, setWelcomeMessageUa] = useState('');
    const [welcomeMessageEn, setWelcomeMessageEn] = useState('');

    const [telegramLink, setTelegramLink] = useState('');
    const [viberLink, setViberLink] = useState('');
    const [useSocialLinks, setUseSocialLinks] = useState(false);

    useEffect(() => {
        if (user) {
            setWelcomeMessageUa(user.application.settings?.welcomeMessageUa as string);
            setWelcomeMessageEn(user.application.settings?.welcomeMessageEn as string);

            setTelegramLink(user.application.settings?.telegramLink as string);
            setViberLink(user.application.settings?.viberLink as string);
            setUseSocialLinks(!!(user.application.settings?.useSocialLinks));
        }
    }, [user]);

    const updateSettings = () => {
        if (user?.application.id && welcomeMessageUa && welcomeMessageEn) {
            const settings = {
                ...user.application.settings,
                welcomeMessageUa,
                welcomeMessageEn,
            }
            console.log(settings);
            const body = {
                settings,
            };
            updateSettingsApi(body, user?.application.id)
        }
    };

    const updateSettingsSocialLinks = () => {
        if (user?.application.id && welcomeMessageUa && welcomeMessageEn) {
            const settings = {
                ...user.application.settings,
                telegramLink,
                viberLink,
                useSocialLinks
            }
            const body = {
                settings,
            };
            updateSettingsApi(body, user?.application.id)
        }
    };

    return (
        <TabBlock className={tab !== 2 ? 'hide' : ''}>
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                noWrap
            >
                {t('settings.widget.title')}
            </Typography>

            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                }}
            >
                {t('settings.widget.generalSettings')}
            </Typography>
            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                }}
            >
                {t('settings.widget.welcomeMessageTitle')}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t('settings.widget.welcomeMessageUa')}
                        multiline
                        maxRows={4}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWelcomeMessageUa(event.target.value)}
                        value={welcomeMessageUa}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t('settings.widget.welcomeMessageEn')}
                        multiline
                        maxRows={4}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWelcomeMessageEn(event.target.value)}
                        value={welcomeMessageEn}
                    />
                </Grid>
            </Grid>
            <ButtonBlue title={t('settings.save')} onClick={updateSettings}/>
            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                    textTransform: 'uppercase'
                }}
            >
                {t('settings.widget.widgetText')}
            </Typography>

            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                }}
            >
                {t('settings.widget.widgetText2')}
            </Typography>

            <div
                style={{
                    borderRadius: '15px',
                    overflow: 'hidden',
                    margin: '10px 0'
                }}
            >
                <CodeBlock
                    text={code}
                    language={'html'}
                    showLineNumbers={true}
                    theme={dracula}
                />
            </div>

            <Box
                sx={{
                    mt: 3,
                    mb: 1,
                }}
            >
                <Typography
                    component="h2"
                    variant="h6"
                    color="inherit"
                    noWrap
                >
                    {t('settings.widget.socialLinksTitle')}
                </Typography>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={useSocialLinks}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUseSocialLinks(event.target.checked);
                            }}
                        />
                    }
                    label={t('settings.widget.socialLinksUse')}
                />

                <Stack
                    spacing={2}
                    sx={{
                        mt: 1,
                    }}
                >
                    <TextField
                        label="Telegram"
                        variant="outlined"
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTelegramLink(event.target.value)}
                        value={telegramLink}
                    />

                    <TextField
                        label="Viber"
                        variant="outlined"
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setViberLink(event.target.value)}
                        value={viberLink}
                    />
                </Stack>
                <ButtonBlue title={t('settings.save')} onClick={updateSettingsSocialLinks}/>

            </Box>
        </TabBlock>
    );
};

export default observer(TabWidget);
