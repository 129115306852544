import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Grid,
    Box,
    Typography,
    Container
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ApiService} from "Utils/ApiService";
import ButtonBlue from "Blocks/ButtonBlue/ButtonBlue";
import ValidateForm from "Utils/ValidateForm";
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "../../Blocks/LanguageSwitcher/LanguageSwitcher";

const theme = createTheme();

interface ErrorStatusT {
    status: boolean,
    message: string,
}

interface ErrorT {
    [key: string]: ErrorStatusT;
}

export const Registration = () => {
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();
    const [error, setError] = useState<ErrorT>({
        email: {
            status: false,
            message: '',
        },
        lastName: {
            status: false,
            message: '',
        },
        firstName: {
            status: false,
            message: '',
        },
        nameCompany: {
            status: false,
            message: '',
        },
        password: {
            status: false,
            message: '',
        },
    });

    useEffect(() => {
        const token = localStorage.getItem('jwt_token');
        if (token) {
            navigate(-1)
        }
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const validate = ValidateForm(data, i18n.language);

        setError({...validate.error});

        if (!validate.isError) {
            const observable$ = ApiService({
                endPoint: 'users/register',
                method: 'POST',
                body: {
                    email: data.get('email'),
                    lastName: data.get('lastName'),
                    firstName: data.get('firstName'),
                    nameCompany: data.get('nameCompany'),
                    password: data.get('password'),
                }
            });
            observable$.subscribe({
                next: (value) => {
                    localStorage.setItem('jwt_token', value.response.jwt);
                    navigate('/settings')
                },
                error(err) {
                    if (err === 'DUPLICATE_EMAIL') {
                        setError({
                            ...validate.error,
                            email: {
                                status: true,
                                message: t('registration.errorEmail'),
                            },
                        });
                    }
                    if (
                        err.includes("password must be longer than or equal to 6 characters") ||
                        err.includes("Password is too weak")
                    ) {
                        setError({
                            ...validate.error,
                            password: {
                                status: true,
                                message: t('registration.errorPassword'),
                            },
                        });
                    }
                },
                complete() {
                    console.log('done');
                },
            });
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    marginTop: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}
            >
                <LanguageSwitcher />
            </Box>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('registration.title')}
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={t('registration.firstName')}
                                    autoFocus
                                    error={error.firstName.status}
                                    helperText={error.firstName.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label={t('registration.lastName')}
                                    name="lastName"
                                    error={error.lastName.status}
                                    helperText={error.lastName.message}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="nameCompany"
                                    label={t('registration.nameCompany')}
                                    name="nameCompany"
                                    error={error.nameCompany.status}
                                    helperText={error.nameCompany.message}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    error={error.email.status}
                                    helperText={error.email.message}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label={t('registration.password')}
                                    type="password"
                                    autoComplete="off"
                                    id="password"
                                    error={error.password.status}
                                    helperText={error.password.message}
                                />
                            </Grid>
                        </Grid>
                        <ButtonBlue title={t('registration.send')} type="submit" fullWidth />
                        <Grid container>
                            <Grid item xs>
                                <Button href={"/login"}>{t('registration.login')}</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
export default Registration;