import {makeAutoObservable, runInAction} from 'mobx';
import { ApiServicePromise } from "Utils/ApiService";

export type ApplicationT = {
    id: number,
    token: string,
    createdAt: Date,
    updatedAt: Date,
    name: string,
    settings?: Record<string, string>,
    operators?: UserT[],
}
export type AppOperatorT = {
    id: number,
    token: string,
    createdAt: Date,
    updatedAt: Date,
    name: string,
}
export type UserT = {
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    blocked: boolean,
    createdAt: Date,
    updatedAt: Date,
    isClose: boolean,
    application: ApplicationT,
    myApplicationId?: number,
    telegramToken?: string,
    appOperator: AppOperatorT[],
}
type editUserApiT = {
    email?: string;
    firstName?: string;
    lastName?: string;
};
type changePasswordApiT = {
    oldPassword: string;
    password: string;
};
type updateSettingsApiT = {
    [key: string]: string | number | boolean | undefined;
};

export default class UserStore {
    isLoading: boolean = false;
    data: UserT | null = null;
    error: string | Record<string, string | Record<string, string | boolean>> | null = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    getUser() {
        this.isLoading = true;
        const headers = localStorage.getItem('appId') ? {'application': localStorage.getItem('appId')} : {};
         ApiServicePromise({
            endPoint: `users`,
            method: 'GET',
            headers
        }).then((res) => {
             localStorage.setItem('appId', res.application.id);
             localStorage.setItem('myApplicationId', res.myApplicationId);
             runInAction(() => {
                 this.data = res;
                 this.isLoading = false;
             });
        }).catch((err) => {
            this.error = err;
        });
    }

    editUserApi(body: editUserApiT) {
        this.isLoading = true;
        ApiServicePromise({
            endPoint: `users/edit`,
            method: 'PUT',
            body
        }).then((res) => {
            this.isLoading = false;
        });
    }

    changePasswordApi(body: changePasswordApiT) {
        this.isLoading = true;

        ApiServicePromise({
            endPoint: `users/change-password`,
            method: 'PUT',
            body
        }).then((res) => {
            this.isLoading = false;
            this.error = null;
        }).catch((err) => {
            if (
                err === 'INVALID_PASSWORD'
            ) {
                this.error = {
                    oldPassword: {
                        status: true,
                        message: 'Недійсний пароль',
                    },
                }
            }
            if (
                err.includes("password must be longer than or equal to 6 characters") ||
                err.includes("Password is too weak")
            ) {
                this.error = {
                    password: {
                        status: true,
                        message: 'Недійсний пароль',
                    },
                }
            }
            this.isLoading = false;
        });
    }

    updateSettingsApi(body: any, id: number) {
        this.isLoading = true;
        ApiServicePromise({
            endPoint: `application/${id}`,
            method: 'PUT',
            body
        }).then((res) => {
            this.isLoading = false;
        });
    }

    updateTelegramTokenApi(id: number) {
        this.isLoading = true;
        ApiServicePromise({
            endPoint: `application/update-telegram-token`,
            method: 'PUT',
            body: {
                id
            },
        }).then((res) => {
            this.data = {
                ...this.data as UserT,
                telegramToken: res.token
            };
            this.isLoading = false;
        });
    }
}
