import styled from 'styled-components'

export const HeaderBlock = styled.header`
  position: static;
  //width: 100%;
  background-color: white;
  //border-bottom: 1px solid rgb(239, 243, 244);
  
  margin: 10px;
  width: calc(100% - 20px);
  //background-color: #bfcfff;
  border-radius: 15px;
`
