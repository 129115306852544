import React, {useEffect, useMemo, useState} from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {AvatarBlock, ButtonCloseChat, ChatItem, NameChat, RoomListBlock} from "./style";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStore } from "stores";


const useStyles = makeStyles({
    materialArea: {
        borderRadius: '15px !important',
        boxShadow: 'none !important',
        border: '1px solid #E7EBF0 !important',
        margin: '10px !important',
        marginTop: '0 !important',
        marginRight: '0 !important',
    },
    borderRight: {
        borderRight: '1px solid rgb(239,243,244)'
    },
    borderBottom: {
        borderBottom: '1px solid rgb(239,243,244)'
    },
});

interface RoomListT {
    isArchive?: boolean;
    isCloseChat?: boolean;
    id?: string;
}

export const RoomList = observer(({ isArchive, isCloseChat, id }: RoomListT) => {
    const { t } = useTranslation('common');

    const {
        userStore: {
            data: user,
        },
        roomState: {
            isLoading,
            rooms,
            selectRoom,
            getRooms,
            setSelected,
            closeChat: closeChatApi,
        },
        chatStore: {
            newMessageCount,
        }
    } = useStore();

    const classes = useStyles();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getRooms(isArchive)
        return () => {
            setSelected(null);
        }
    }, []);

    useEffect(() => {
        if (id && !selectRoom && !isLoading) {
            setSelected(Number(id));
        }
    }, [id, selectRoom, isLoading]);

    useEffect(() => {
        if (isCloseChat) {
            navigate(`/archive/${selectRoom?.id}`);
        }
    }, [isCloseChat]);

    useEffect(() => {
        if (Number.isInteger(newMessageCount) && !isArchive) {
            getRooms();
        }
    }, [newMessageCount])

    const locationChat = (id: number) => {
        setSelected(id);
        if (isArchive) {
            navigate(`/archive/${id}`)
        } else {
            navigate(`/chat/${id}`)
        }
    };

    const handleDialog = () => {
        setOpen(!open);
    };

    const closeChat = (id?: number) => {
        if (id && user?.application.token) {
            closeChatApi(id, user?.application.token);
            handleDialog();
            navigate(`/chat`);
        }
    }

    const roomsRender = useMemo(() => {
        return rooms?.map((el) => (
            <ChatItem
                onClick={() => locationChat(el.id)}
                key={el.id}
                className={selectRoom?.id === el.id ? 'active' : ''}
            >
                <Badge badgeContent={el.notReaded} color="primary" anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                    <AvatarBlock>
                        <PersonOutlineIcon />
                    </AvatarBlock>
                </Badge>
                <ListItemText primary={el.device.name || `User ${el.id}`}>
                    {el.device.name || `User ${el.id}`}
                </ListItemText>
            </ChatItem>
        ));
    }, [rooms, selectRoom]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`${t('chat.dialogTitleCloseChat')} ${selectRoom && (selectRoom.device.name || `User ${selectRoom.id}`)}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('chat.dialogCloseChat')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialog}>{t('chat.dialogCancelChatButton')}</Button>
                    <Button onClick={() => closeChat(selectRoom?.id)} autoFocus>
                        {t('chat.dialogCloseChatButton')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={3} sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                {selectRoom && !isArchive && (
                    <List className={classes.materialArea}>
                        <ListItem key="RemySharp">
                            <AvatarBlock>
                                <PersonOutlineIcon />
                            </AvatarBlock>
                            <NameChat>
                                <ListItemText primary={selectRoom.device.name || `User ${selectRoom.id}`} />
                            </NameChat>
                            <ButtonCloseChat
                                onClick={handleDialog}
                            >
                                {t('chat.closeChatButton')}
                            </ButtonCloseChat>
                        </ListItem>
                    </List>
                )}
                <RoomListBlock>
                    {isLoading ? (
                        <>...Loading</>
                    ) : roomsRender}
                </RoomListBlock>
            </Grid>
        </>
    );
});

export default RoomList;
