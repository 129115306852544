import styled from "styled-components";
import { GlobalThemeSettings } from "Utils/GlobalThemeSettings";

export const SidebarUsersBlock = styled.div`
  border-radius: 15px;
  box-shadow: none;
  border: 1px solid #E7EBF0;
  margin: 0 0 0 10px;
  max-height: calc(100vh - 217px);
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  padding: 5px;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const SidebarUsersItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 15px 10px;
  border-radius: 15px;
  transition: 0.3s;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: ${GlobalThemeSettings.color.sidebar};
    color: white;
  }
  &.active {
    background-color: #bfcfff;
    color: black;
  }
`;
