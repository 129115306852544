import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import {
    TabBlock,
} from "../style";
import {
    Loop,
} from '@mui/icons-material';
import { CopyField } from '@eisberg-labs/mui-copy-field';
import ButtonBlue from "Blocks/ButtonBlue/ButtonBlue";
import ValidateForm from "Utils/ValidateForm";
import { useTranslation } from "react-i18next";
import { GlobalThemeSettings } from "Utils/GlobalThemeSettings";
import {useStore} from "stores";
import {observer} from "mobx-react-lite";

type ErrorStatusT = {
    status: boolean;
    message: string;
};
type ErrorT = {
    [key: string]: ErrorStatusT;
};
type TabProfileT = {
    tab: number
};

export const TabProfile = ({ tab }: TabProfileT) => {
    const { t } = useTranslation('common');
    const { userStore: {data: user, changePasswordApi, editUserApi, updateTelegramTokenApi} } = useStore();

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [error, setError] = useState<ErrorT>({
        oldPassword: {
            status: false,
            message: '',
        },
        password: {
            status: false,
            message: '',
        },
    });

    useEffect(() => {
        if (user) {
            setEmail(user.email)
            setFirstName(user.firstName)
            setLastName(user.lastName)
        }
    }, [user]);

    const updateProfile = () => {
        const bodyData = {
            email: email,
            firstName: firstName,
            lastName: lastName,
        };
        const entries = Object.entries(bodyData).filter(([key,val]) => val !== '' && val !== null);
        const body = Object.fromEntries(entries);
        editUserApi(body)
    };

    const updatePassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const validate = ValidateForm(data);
        setError({...validate.error});

        if (!validate.isError) {
            const oldPassword = data.get('oldPassword') as string;
            const password = data.get('password') as string;
            changePasswordApi({
                oldPassword,
                password,
            })
        }
    };

    const updateTelegramToken = () => {
        updateTelegramTokenApi(user?.application.id as number)
    };

    return (
        <TabBlock className={tab !== 1 ? 'hide' : ''}>
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                noWrap
            >
                {t('settings.profile.title')}
            </Typography>
            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                }}
            >
                {t('settings.profile.title')}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        id="firstName"
                        label={t('settings.profile.inputName')}
                        name="firstName"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
                        value={firstName}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        id="lastName"
                        label={t('settings.profile.inputLastName')}
                        name="lastName"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value)}
                        value={lastName}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                        value={email}
                    />
                </Grid>
            </Grid>
            <ButtonBlue title={t('settings.save')} onClick={updateProfile} />
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <CopyField
                        fullWidth
                        label={t('settings.profile.telegramKey')}
                        value={user?.telegramToken || 'telegram_token:'}
                        copyTooltip={`${t('settings.profile.copy')}`}
                        disabled
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button
                        type="button"
                        variant="contained"
                        sx={[
                            {
                                '&:hover': {
                                    backgroundColor: GlobalThemeSettings.color.blue,
                                    boxShadow: 'none',
                                },
                            },
                            {
                                boxShadow: 'none',
                                borderRadius: '15px',
                                p: 2,
                                backgroundColor: GlobalThemeSettings.color.blue,
                            }
                        ]}
                        onClick={() => updateTelegramToken()}
                    >
                        <Loop />
                    </Button>
                </Grid>
            </Grid>
            <Box component="form" noValidate onSubmit={updatePassword}>
                <Typography
                    component="div"
                    color="inherit"
                    sx={{
                        mt: 1,
                        mb: 1,
                    }}
                >
                    {t('settings.profile.passwordTitle')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="oldPassword"
                            label={t('settings.profile.inputOldPassword')}
                            name="oldPassword"
                            type="password"
                            autoComplete="off"
                            error={error.oldPassword?.status}
                            helperText={error.oldPassword?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="password"
                            label={t('settings.profile.inputPassword')}
                            name="password"
                            type="password"
                            autoComplete="off"
                            error={error.password?.status}
                            helperText={error.password?.message}
                        />
                    </Grid>
                </Grid>
                <ButtonBlue title={t('settings.profile.savePassword')} type="submit" />
            </Box>
        </TabBlock>
    );
};

export default observer(TabProfile);
