import {makeAutoObservable, runInAction} from 'mobx';
import {ApiServicePromise} from "Utils/ApiService";
import {UserT} from "../User/UserStore";
import {t} from "i18next";

type RegisterOperatorT = {
    email: string;
    lastName: string;
    firstName: string;
    password: string;
};
type ErrorStatusT = {
    status: boolean,
    message: string,
}
type ErrorT = {
    [key: string]: ErrorStatusT;
}
const initError: ErrorT = {
    email: {
        status: false,
        message: '',
    },
    lastName: {
        status: false,
        message: '',
    },
    firstName: {
        status: false,
        message: '',
    },
    nameCompany: {
        status: false,
        message: '',
    },
    password: {
        status: false,
        message: '',
    },
}

export default class OperatorStore {
    isLoading = true;
    currentOperator: UserT | null = null;
    operators: UserT[] | null = null;
    error: ErrorT = initError;
    notUser = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setOperator(user: UserT | null) {
        this.currentOperator = user;
    }

    setError(error: ErrorT) {
        this.error = error;
    }

    setNotUser(status: boolean) {
        this.notUser = status;
    }

    getOperators(id: string) {
        ApiServicePromise({
            endPoint: `users/operators/${id}`,
            method: 'GET',
        }).then((res) => {
            runInAction(() => {
                this.operators = res;
                this.isLoading = false;
            });
        });
    }

    removeOperatorApi(id: number) {
        ApiServicePromise({
            endPoint: `application/operator/${id}`,
            method: 'DELETE',
        }).then(() => {
            runInAction(() => {
                this.operators = this.operators && this.operators.filter((el) => el.id !== id);
            });
            this.setOperator(null);
        });
    }

    registerOperatorApi(body: RegisterOperatorT) {
        ApiServicePromise({
            endPoint: `application/register-operator`,
            method: 'POST',
            body,
        }).then((res) => {
            if (res.message) {
                switch (res.message) {
                    case 'DUPLICATE_EMAIL':
                        runInAction(() => {
                            this.error.email = {
                                status: true,
                                message: t('registration.errorEmail') as string,
                            };
                        });
                        break;
                    case (
                        res.message.includes("password must be longer than or equal to 6 characters") ||
                        res.message.includes("Password is too weak")
                    ):
                        runInAction(() => {
                            this.error.password = {
                                status: true,
                                message: t('registration.errorPassword') as string,
                            };
                        });
                        break;
                    default:
                        runInAction(() => {
                            this.error.email = {
                                status: true,
                                message: res.message,
                            };
                        });
                }
            } else {
                this.setOperator(res);
                this.operators = this.operators ? [
                    ...this.operators,
                    res
                ] : [
                    res
                ];
            }
        });
    }

    addOperatorApi(email: string) {
        ApiServicePromise({
            endPoint: `application/operator`,
            method: 'POST',
            body: {
                email
            },
        }).then((res) => {
            if (res.message) {
                switch (res.message) {
                    case 'USER_NOT_FOUND':
                        runInAction(() => {
                            this.notUser = true;
                        });
                        break;
                    case 'ALREADY_ADDED':
                        runInAction(() => {
                            this.error.email = {
                                status: true,
                                message: t('registration.alreadyAdded') as string,
                            };
                        });
                        break;
                    default:
                        runInAction(() => {
                            this.error.email = {
                                status: true,
                                message: res.message,
                            };
                        });
                }
            } else {
                this.setOperator(res);
                runInAction(() => {
                    this.operators = this.operators ? [
                        ...this.operators,
                        res
                    ] : [
                        res
                    ];
                });
            }
        });
    }
}
