import React, { useEffect } from 'react';
import {
    Grid,
} from '@mui/material';
import MessageTextEditorSimple from "Components/MessageTextEditorSimple/MessageTextEditorSimple";
import ChatBlock from "Components/ChatBlock/ChatBlock";
import { WelcomeText, СhatArea } from "./style";
import RoomList from "Components/RoomList/RoomList";
import { useSocket } from "Hooks/useSocket";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import MainWrapper from "Components/MainWrapper/MainWrapper";
import { observer } from "mobx-react-lite";
import { useStore } from "stores";


export const Chat = observer(() => {
    const { t } = useTranslation('common');
    const { id } = useParams();

    const {
        userStore: {
            data: user,
        },
        chatStore: {
            getChatApplication,
            clearMessages,
        }
    } = useStore();

    const {
        sendMessage,
        joinRoom,
        leaveRoom,
        isClose,
    } = useSocket();

    useEffect(() => {
        return () => {
            clearMessages();
        }
    }, []);

    useEffect(() => {
        if (id) {
            getChatApplication(id);
        }
        return () => {
            leaveRoom({
                chatId: Number(id)
            });
        }
    }, [id]);

    useEffect(() => {
        if (user?.application.id && Number(id)) {
            joinRoom({
                applicationId: user.application.id,
                chatId: Number(id),
            });
        }
    }, [user, id]);

    return (
        <>
            <RoomList isCloseChat={isClose} id={id} />
            <Grid item xs={9}>
                <СhatArea>
                    {id ? (
                        <>
                            <ChatBlock />
                            <Grid container style={{padding: '10px 0'}}>
                                <MessageTextEditorSimple
                                    sendMessage={sendMessage}
                                    applicationId={user?.application.id}
                                    chatId={Number(id)}
                                />
                            </Grid>
                        </>
                    ) : (
                        <WelcomeText>
                            {t('chat.selectChat')}
                        </WelcomeText>
                    )}
                </СhatArea>
            </Grid>
        </>
    );
});

export default Chat;
