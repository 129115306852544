import styled from 'styled-components'

export const СhatArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  border-radius: 15px;
  box-shadow: none;
  border: 1px solid #E7EBF0;
  margin: 0 10px;
  padding: 0 10px;
`;

export const WelcomeText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;