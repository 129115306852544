import React, { useMemo, useRef } from 'react';
import {MessageRight, MessageLeft, MessagesBlock, MessagesBlockDay, WelcomeMessage, FileBlock} from "./style";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Linkify from 'react-linkify';
import { SecureLink } from "react-secure-link"
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {useStore} from "stores";


export const ChatWidget = observer(() => {
    const { i18n } = useTranslation()
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const {
        chatStore: {
            messages,
            name,
        },
        widgetSettingsStore: {
            settings,
        },
    } = useStore();

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }

    const messagesRender = useMemo(() => {
        const result = messages?.map((el) => el?.user || el?.deviceToken ? (
            <MessageRight
                key={el.id}
                backgroundColor={settings?.chatRightMessageBackground}
                color={settings?.chatRightMessageColor}
                onClick={() => (el.file && !el.file.name.includes('blob')) ? window.open(`${process.env.REACT_APP_SERVER_URL}/${el.file.path}`,'_blank') : null}
            >
                {!el.text && el.file ? (
                    <FileBlock>
                        {el.file.name.includes('blob') ? (
                            <audio controls src={`${process.env.REACT_APP_SERVER_URL}/${el.file.path}`} />
                        ) : (
                            <>
                                <InsertDriveFileIcon />
                                {el.file.name}
                            </>
                        )}
                    </FileBlock>
                ) : (
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                        <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                    )}>
                        {el.text}
                    </Linkify>
                )}
            </MessageRight>
        ) : (
            <MessageLeft
                key={el.id}
                backgroundColor={settings?.chatLeftMessageBackground}
                color={settings?.chatLeftMessageColor}
                onClick={() => (el.file && !el.file.name.includes('blob')) ? window.open(`${process.env.REACT_APP_SERVER_URL}/${el.file.path}`,'_blank') : null}
            >
                {!el.text && el.file ? (
                    <FileBlock>
                        {el.file.name.includes('blob') ? (
                            <audio controls src={`${process.env.REACT_APP_SERVER_URL}/${el.file.path}`} />
                        ) : (
                            <>
                                <InsertDriveFileIcon />
                                {el.file.name}
                            </>
                        )}
                    </FileBlock>
                ) : (
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                        <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                    )}>
                        {el.text}
                    </Linkify>
                )}
            </MessageLeft>
        ))
        setTimeout(() => {
            scrollToBottom();
        }, 0)
        return result;
    }, [messages])

    return (
        <>
            {name ? (
                <MessagesBlock
                    ref={messagesEndRef}
                    backgroundColor={settings?.chatPageBackground}
                >
                    <MessagesBlockDay>
                        {settings?.welcomeMessageUa && i18n.language === 'ua' && (
                            <MessageLeft
                                backgroundColor={settings?.chatLeftMessageBackground}
                                color={settings?.chatLeftMessageColor}
                            >
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                                )}>
                                    {settings?.welcomeMessageUa}
                                </Linkify>
                            </MessageLeft>
                        )}
                        {settings?.welcomeMessageEn && i18n.language === 'en' && (
                            <MessageLeft
                                backgroundColor={settings?.chatLeftMessageBackground}
                                color={settings?.chatLeftMessageColor}
                            >
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                                )}>
                                    {settings?.welcomeMessageEn}
                                </Linkify>
                            </MessageLeft>
                        )}
                        {messagesRender}
                    </MessagesBlockDay>
                </MessagesBlock>
            ): (
                <WelcomeMessage
                    backgroundColor={settings?.startPageBackground}
                    color={settings?.startPageColor}
                    fontWeight={settings?.startPageFontWeight}
                    fontSize={settings?.fontSizeStartPage}
                >
                    <p>
                        {(i18n.language === 'ua' ? settings?.textStartPage : settings?.textStartPageEn) ||
                        'Маєте питання? Наша команда завжди готова зарадити!'}
                    </p>
                </WelcomeMessage>
            )}
        </>
    );
});
export default ChatWidget;