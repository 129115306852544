export {
    dashboard,
    chat,
    settings,
    logout,
    login,
    register,
    archive,
    users,
} from './Pathnames';

export const checkLocalStorage = () => {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}
