import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Box,
    Container,
    Grid,
    Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ApiService} from "Utils/ApiService";
import ButtonBlue from "Blocks/ButtonBlue/ButtonBlue";
import ValidateForm from "Utils/ValidateForm";
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "../../Blocks/LanguageSwitcher/LanguageSwitcher";


const theme = createTheme();

interface ErrorStatusT {
    status: boolean,
    message: string,
}

interface ErrorT {
    [key: string]: ErrorStatusT;
}

export const Login = () => {
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();
    const [error, setError] = useState<ErrorT>({
        email: {
            status: false,
            message: '',
        },
        password: {
            status: false,
            message: '',
        },
    });

    useEffect(() => {
        const token = localStorage.getItem('jwt_token');
        if (token) {
            navigate(-1)
        }
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const validate = ValidateForm(data, i18n.language);
        setError({...validate.error});

        if (!validate.isError) {
            const observable$ = ApiService({
                endPoint: 'users/login',
                method: 'POST',
                body: {
                    email: data.get('email'),
                    password: data.get('password'),
                }
            });
            observable$.subscribe({
                next: (value) => {
                    localStorage.setItem('jwt_token', value.response.jwt);
                    navigate('/settings')
                },
                error(err) {
                    if (err === 'INVALID_EMAIL') {
                        setError({
                            email: {
                                status: true,
                                message: t('login.errorEmail'),
                            },
                            password: {
                                status: true,
                                message: t('login.errorEmail'),
                            },
                        });
                    }
                    if (
                        err === 'INVALID_PASSWORD' ||
                        err.includes("password must be longer than or equal to 6 characters") ||
                        err.includes("Password is too weak")
                    ) {
                        setError({
                            email: {
                                status: false,
                                message: '',
                            },
                            password: {
                                status: true,
                                message: t('login.errorPassword'),
                            },
                        });
                    }
                },
                complete() {
                    setError({
                        email: {
                            status: false,
                            message: '',
                        },
                        password: {
                            status: false,
                            message: '',
                        },
                    });
                },
            });
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    marginTop: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}
            >
                <LanguageSwitcher />
            </Box>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('login.title')}
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            error={error.email.status}
                            helperText={error.email.message}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('login.password')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={error.password.status}
                            helperText={error.password.message}
                        />
                        {/*<Grid container>*/}
                        {/*    <FormControlLabel*/}
                        {/*        control={<Checkbox value="remember" color="primary" />}*/}
                        {/*        label="Запам'яти мене"*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <ButtonBlue title={t('login.send')} type="submit" fullWidth />
                        <Grid container>
                            <Grid item xs>
                                <Button href={"/registration"}>
                                    {t('login.registration')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Login;