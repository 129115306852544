import React, {useRef, useState} from 'react';
import useOutsideClicker from "Hooks/useOutsideClicker";
import {
    ButtonChat,
    EmojiWrapper,
    InputRow,
    RecordTime,
    TextArea,
    TextAreaWrapper,
} from "./style";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { AttachFile, Close, Mic, Send, Check } from "@mui/icons-material";
import axios from "axios";
import { ErrorBlock } from "../FooterWidget/style";
import { useTranslation } from "react-i18next";
import useRecorder from "Hooks/useRecorder";

export const MessageTextEditorSimple = ({sendMessage, applicationId, chatId}: any) => {
    const { t } = useTranslation('common');
    const [text, setText] = useState<string>('');
    const [errorFile, setErrorFile] = useState<string>('');

    const emojiRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { show: show, setShow: setShow } = useOutsideClicker(emojiRef, 'emoji-btn');

    const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setText(e.currentTarget.value);
    };

    const onFormSubmit = (file = null) => {
        if (text.trim() || file) {
            const messageObject = {
                applicationId,
                chatId,
                createdAt: new Date(),
                text: file ? null : text,
                file: file ? file : null,
            };
            sendMessage(messageObject);
            setText('');
            if (inputRef.current) {
                inputRef.current.style.height = 'auto';
            }
        }
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const sendFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.currentTarget;

        if (!input.files?.length) {
            return;
        }

        if(input.files[0].size > (1024 * 1024 * 10)) {
            setErrorFile(`${t('chat.errorMaxSizeFile')} 10 Мб`);
            setInterval(() => {
                setErrorFile('');
            }, 30000)
        }

        const data = new FormData()
        data.append('file', input.files[0])

        axios.post(`${process.env.REACT_APP_SERVER_URL}/file/upload`, data)
            .then((res) => onFormSubmit(res.data))
            .catch((err) => {
                let errorText = '';

                if (err.response.data.message.includes("Validation failed (expected size is less than")) {
                    const bytes = err.response.data.message.replace ( /[^\d.]/g, '' );
                    errorText = `${t('chat.errorMaxSizeFile')} ${formatBytes(Number(bytes))}`
                }

                if (err.response.data.message.includes("Validation failed (expected type is .")) {
                    errorText = `${t('chat.errorTypeFile')}`
                }

                setErrorFile(errorText);
                setInterval(() => {
                    setErrorFile('')
                }, 30000)
            });

        e.target.value = '';
    }

    const { recorderState, ...handlers } = useRecorder();
    const { audio, audioBlob, recordingMinutes, recordingSeconds, initRecording } = recorderState;

    const sendAudio = () => {
        if (audioBlob) {
            const data = new FormData()
            data.append('file', audioBlob)

            axios.post(`${process.env.REACT_APP_SERVER_URL}/file/upload`, data)
                .then((res) => {
                    onFormSubmit(res.data);
                    handlers.cancelRecording();
                })
                .catch((err) => {
                    let errorText = '';

                    if (err.response.data.message.includes("Validation failed (expected size is less than")) {
                        const bytes = err.response.data.message.replace ( /[^\d.]/g, '' );
                        errorText = `${t('chat.errorMaxSizeFile')} ${formatBytes(Number(bytes))}`
                    }

                    if (err.response.data.message.includes("Validation failed (expected type is .")) {
                        errorText = `${t('chat.errorTypeFile')}`
                    }

                    setErrorFile(errorText);
                    setInterval(() => {
                        setErrorFile('')
                    }, 30000)
                });
        }
    }

    return (
        <>
            {errorFile && (
                <ErrorBlock>
                    {errorFile}
                </ErrorBlock>
            )}

            <InputRow>
                {show && (
                    <EmojiWrapper ref={emojiRef}>
                        <Picker
                            data={data}
                            onEmojiSelect={(e: any) => setText(text + e.native)}
                        />
                    </EmojiWrapper>
                )}
                {(!initRecording && !audio) && (
                    <ButtonChat>
                        <input
                            type="file"
                            id="file"
                            style={{
                                display: 'none',
                            }}
                            accept="image/png, image/jpeg, video/mp4, video/quicktime"
                            onChange={sendFile}
                        />
                        <label htmlFor="file">
                            <AttachFile />
                        </label>
                    </ButtonChat>
                )}
                <TextAreaWrapper
                    className={(initRecording || audio) ? 'record-wrapper' : ''}
                >
                    {initRecording ? (
                        <RecordTime>
                            <span>{recordingMinutes < 10 ? `0${recordingMinutes}` : `${recordingMinutes}`}</span>
                            <span>:</span>
                            <span>{recordingSeconds < 10 ? `0${recordingSeconds}` : `${recordingSeconds}`}</span>
                        </RecordTime>
                    ) : audio ? (
                        <audio controls src={audio} />
                    ) : (
                        <TextArea
                            onInput={function (e) {
                                if (inputRef.current) {
                                    inputRef.current.style.height = 'auto';
                                    if (inputRef.current.value.length > 0) {
                                        inputRef.current.style.height =
                                            inputRef.current.scrollHeight + 'px';
                                    }
                                }
                            }}
                            value={text}
                            onChange={handleChange}
                            rows={1}
                            placeholder={`${t('chat.inputPlaceholder')}`}
                            maxLength={10000}
                            ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                        />
                    )}
                </TextAreaWrapper>

                {(initRecording || audio) ? (
                    <>
                        <ButtonChat onClick={() => handlers.cancelRecording()}>
                            <Close />
                        </ButtonChat>
                        {audio ? (
                            <ButtonChat onClick={() => {
                                sendAudio();
                            }}>
                                <Send />
                            </ButtonChat>
                        ) : (
                            <ButtonChat onClick={() => {
                                handlers.saveRecording();
                            }}>
                                <Check />
                            </ButtonChat>
                        )}
                    </>
                ) : (
                    <>
                        <ButtonChat
                            onClick={() => {
                                setShow(!show)
                            }}
                            className="emoji-btn"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="react-input-emoji--button--icon"
                            >
                                {/* eslint-disable-next-line max-len */}
                                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10" />
                                {/* eslint-disable-next-line max-len */}
                                <path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0" />
                            </svg>
                        </ButtonChat>
                        <ButtonChat onClick={() => handlers.startRecording()}>
                            <Mic />
                        </ButtonChat>
                        <ButtonChat onClick={() => onFormSubmit()}>
                            <Send />
                        </ButtonChat>
                    </>
                )}

            </InputRow>
        </>
    );
}

export default MessageTextEditorSimple;
