import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Widget from "./Widget/widget";
import Login from "./Page/Login/Login";
import Registration from "./Page/Registration/Registration";
import Dashboard from "./Page/Dashboard/Dashboard";
import Chat from "./Page/Chat/Chat";
import Settings from "./Page/Settings/Settings";
import Archive from "./Page/Archive/Archive";
import {useTranslation} from "react-i18next";
import {checkLocalStorage} from "./Utils";
import {Users} from "./Page/Users/Users";
import ChatBotLogin from "./Page/Chat/ChatBotLogin";
import MainWrapper from "./Components/MainWrapper/MainWrapper";


function App() {
    const { i18n } = useTranslation()

    useEffect(() => {
        let language = null;
        if (checkLocalStorage()) {
            language = localStorage.getItem('language');
        }
        if (language) {
            i18n.changeLanguage(language);
        }
    }, []);

    return (
    <div className="App">
        <Routes>
            <Route path="/" element={<Navigate to="/settings" />}/>
            <Route path="/widget/:token" element={<Widget />}/>
            <Route path="/login" element={<Login />}/>
            <Route path="/registration" element={<Registration />}/>
            <Route path="/chat/bot/:id" element={<ChatBotLogin />}/>
            <Route element={<MainWrapper />}>
                <Route path="/dashboard" element={<Dashboard />}/>
                <Route path="/chat" element={<Chat />}/>
                <Route path="/chat/:id" element={<Chat />}/>
                <Route path="/archive" element={<Archive />}/>
                <Route path="/archive/:id" element={<Archive />}/>
                <Route path="/users" element={<Users />}/>
                <Route path="/users/:id" element={<Users />}/>
                <Route path="/settings" element={<Settings />}/>
            </Route>
        </Routes>
    </div>
  );
}

export default App;
