import React, {useRef, useState} from 'react';
import { Send, AttachFile } from '@mui/icons-material';
import {
    InputRow,
    TextArea,
    TextAreaWrapper,
    ButtonChat
} from "./style";
import {useTranslation} from "react-i18next";

interface ChatInputSettingsT {
    backgroundColor: string;
    inputColor: string;
    inputBackground: string;
    inputInputPlaceholderColor: string;
}

export const ChatInputSettings = ({backgroundColor, inputColor, inputBackground, inputInputPlaceholderColor}: ChatInputSettingsT) => {
    const { t } = useTranslation('common');
    const [text, setText] = useState('');
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setText(e.currentTarget.value);
    };

    return (
        <InputRow
            backgroundColor={backgroundColor}
        >
            <ButtonChat>
                <AttachFile />
            </ButtonChat>
            <TextAreaWrapper
                backgroundColor={inputBackground}
            >
                <TextArea
                    backgroundColor={inputBackground}
                    color={inputColor}
                    placeholderColor={inputInputPlaceholderColor}
                    onInput={function (e) {
                        if (inputRef.current) {
                            inputRef.current.style.height = 'auto';
                            if (inputRef.current.value.length > 0) {
                                inputRef.current.style.height =
                                    inputRef.current.scrollHeight + 'px';
                            }
                        }
                    }}
                    value={text}
                    onChange={handleChange}
                    rows={1}
                    placeholder={`${t('widget.messagePlaceholder')}`}
                    maxLength={10000}
                    ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                />
            </TextAreaWrapper>
            <ButtonChat
                className="emoji-btn"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="react-input-emoji--button--icon"
                >
                    {/* eslint-disable-next-line max-len */}
                    <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10" />
                    {/* eslint-disable-next-line max-len */}
                    <path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0" />
                </svg>
            </ButtonChat>
            <ButtonChat>
                <Send />
            </ButtonChat>
        </InputRow>
    );
};
export default ChatInputSettings;