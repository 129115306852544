import styled from 'styled-components'
import {GlobalThemeSettings} from "Utils/GlobalThemeSettings";

type SettingsT = {
    backgroundColor?: string;
    color?: string
    fontWeight?: string;
    fontSize?: string;
}

export const WelcomeMessage = styled.div<SettingsT>`
  text-align: center;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.backgroundColor || '#f3f5f8'};
  p {
    line-height: 150%;
    margin: 0;
    color: ${props => props.color || GlobalThemeSettings.color.text};
    font-weight: ${props => props.fontWeight || '400'};
    font-size: ${props => (props.fontSize && `${props.fontSize}px`) || '14px'};
    white-space: pre-line;
  }
`

export const MessagesBlock = styled.div<SettingsT>`
  background-color: ${props => props.backgroundColor || '#f3f5f8'};
  padding: 10px;
  flex: 1 1 0;
  -ms-flex: 1 1 1%;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  &::-webkit-scrollbar {
    width: 0;
  }
`

export const MessagesBlockDay = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`

export const Message = styled.div<SettingsT>`
  padding: 5px 15px;
  font-size: 16px;
  line-height: 150%;
  border-radius: 20px;
  margin-bottom: 5px;
  white-space: pre-line;
  text-align: left;
  max-width: 250px;
  word-break: break-all;
`

export const MessageRight = styled(Message)`
  //background-color: #FEE600;
  background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
  color: ${props => props.color || GlobalThemeSettings.color.white};
  margin-left: auto;
  &:has(audio) {
    background: none;
    padding: 5px 0;
  }
`

export const MessageLeft = styled(Message)`
  //background-color: #38C4B1;
  //color: white;
  background-color: ${props => props.backgroundColor || '#BFCFFF' };
  color: ${props => props.color || 'rgb(51, 51, 51)'};
  margin-right: auto;
  &:has(audio) {
    background: none;
    padding: 5px 0;
  }
`

export const FileBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`
