import styled from "styled-components";
import {GlobalThemeSettings} from "../../Utils/GlobalThemeSettings";

export const LangBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: 0.3s;
    font-weight: 500;
    &:hover {
      color: ${GlobalThemeSettings.color.sidebar};
    }
    &.active {
      cursor: auto;
      color: ${GlobalThemeSettings.color.blue};
      &:hover {
        color: ${GlobalThemeSettings.color.blue};
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
`
