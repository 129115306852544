import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
} from '@mui/material';
import { useParams } from "react-router";
import { ApiService } from "Utils/ApiService";
import ChatBot from "./ChatBot";

const getQuery = () => {
    return new URLSearchParams(window.location.search);
}

export const ChatBotLogin = () => {
    const [load, setLoad] = useState(true);
    const { id } = useParams();

    const telegramId = getQuery().get('telegramId');

    useEffect(() => {
        if (telegramId) {
            const token = localStorage.getItem('jwt_token');
            if (!token) {
                const observable$ = ApiService({
                    endPoint: `chat/login-telegram/${telegramId}`,
                    method: 'POST',
                });
                observable$.subscribe({
                    next: (value) => {
                        localStorage.setItem('jwt_token', value.response.jwt);
                    },
                    error(err) {
                        console.log(err);
                    },
                    complete() {
                        setLoad(false);
                    },
                });
            } else {
                setLoad(false);
            }
        }
    }, [telegramId]);

    return (
        <>
            {load ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                    <CircularProgress color="inherit" />
                </Box>
            ) : id && (
                <ChatBot id={id} />
            )}
        </>
    );
}

export default ChatBotLogin;
