import { makeAutoObservable, runInAction } from 'mobx';
import { ApiServicePromise } from "Utils/ApiService";

type ErrorStatusT = {
    status: boolean,
    message: string,
}
type ErrorT = {
    [key: string]: ErrorStatusT;
}

export default class DeviceStore {
    deviceToken: string | null = null;
    isDeviceCreate = false;
    error: ErrorT | null = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setDeviceToken(deviceToken: string | null) {
        this.deviceToken = deviceToken;
    }

    setIsDeviceCreate(isDeviceCreate: boolean) {
        this.isDeviceCreate = isDeviceCreate;
    }

    deviceCreate(token: string, name?: string) {
        ApiServicePromise({
            endPoint: 'device/create',
            method: 'POST',
            body: {
                applicationToken: token,
                device: {},
                name,
            }
        }).then((res) => {
            runInAction(() => {
                this.deviceToken = res.token;
                this.isDeviceCreate = true;
            });
        });
    }
}
