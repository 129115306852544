import React, { useEffect } from 'react';
import {
    Paper,
    Grid,
    Box,
    ThemeProvider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme } from "@mui/material/styles";
import Sidebar from "Components/Sidebar/Sidebar";
import Header from "Components/Header/Header";
import ChatBlock from "Components/ChatBlock/ChatBlock";
import { WelcomeText, СhatArea } from "./style";
import RoomList from "Components/RoomList/RoomList";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStore } from "stores";


export const Archive = observer(() => {
    const { t } = useTranslation('common');
    const {
        chatStore: {
            getChatApplication,
        }
    } = useStore();

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getChatApplication(id)
        }
    }, [id]);

    return (
        <>
            <RoomList isArchive />
            <Grid item xs={9}>
                <СhatArea>
                    {id ? (
                        <ChatBlock />
                    ) : (
                        <WelcomeText>
                            {t('archive.selectChat')}
                        </WelcomeText>
                    )}
                </СhatArea>
            </Grid>
        </>
    );
});

export default Archive;
