import styled from 'styled-components'
import {GlobalThemeSettings} from "../../Utils/GlobalThemeSettings";

export const ButtonArea = styled.div`
  border-radius: 15px;
  box-shadow: none;
  border: 1px solid #E7EBF0;
  margin: 0 0 10px 10px;
  button {
    width: 100%;
    margin: 5px 0;
  }
`;

export const UsersArea = styled.div`
  border-radius: 15px;
  box-shadow: none;
  border: 1px solid #E7EBF0;
  margin: 0 10px;
  padding: 10px 20px;
  text-align: left;
  position: relative;
  transition: height 0.3s;
  overflow: hidden;
`;

export const ButtonGroup = styled.div`
 button:first-child {
   margin-right: 10px;
 }
`;
