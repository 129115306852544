import styled from 'styled-components'
import {GlobalThemeSettings} from "Utils/GlobalThemeSettings";

export const SidebarBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: white;
  svg {
    color: white;
  }
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 15px;
  transition: 0.3s;
  text-align: left;
  svg {
    margin-right: 20px;
  }
  &:hover {
    background-color: ${GlobalThemeSettings.color.blue};
  }
  &.active {
    background-color: ${GlobalThemeSettings.color.darkBlue};
  }
`
