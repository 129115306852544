import styled from 'styled-components'
import {GlobalThemeSettings} from "../../Utils/GlobalThemeSettings";

export const SettingsArea = styled.div`
  border-radius: 15px;
  box-shadow: none;
  border: 1px solid #E7EBF0;
  margin: 0 10px;
  padding: 10px 20px;
  text-align: left;
  position: relative;
  transition: height 0.3s;
  overflow: hidden;
`;

export const TabBlock = styled.div`
  visibility: visible;
  opacity: 1;
  height: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: opacity 1s, visibility 1s, transform 0.3s;

  &.hide {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(-10px, 0, 0);
    height: 0;
    transition: opacity 1s, visibility 1s, transform 0.3s;
  }
`;

type WidgetButtonSettings  ={
    backgroundColor?: string;
    color?: string;
}

export const WidgetButtonTypeTwoSettings = styled.div<WidgetButtonSettings>`
  display: flex;
  align-items: center;
  .label {
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 13px;
    margin-right: 13px;
    border-radius: 25px;
    background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
    color: ${props => props.color || GlobalThemeSettings.color.white};
    box-shadow: 0 1px 6px rgb(0 0 0%);
    white-space: nowrap;
    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      z-index: 11;
      top: 13px;
      right: -8px;
      border-width: 10px 6px;
      border-style: solid;
      border-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }
`;

export const WidgetButtonSettings = styled.div<WidgetButtonSettings>`
  border: 0;
  background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
  color: ${props => props.color || '#FFFFFF'};
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

export const LabelButton = styled.div`
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 10px 0;
  padding: 10px; 
  
  header {
    min-width: 300px;
  }
  
  &.active {
    border: 1px solid ${GlobalThemeSettings.color.blue};
  }
`;

export const ChatBlock = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 450px;
`;