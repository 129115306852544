import React, { useEffect, useState } from 'react';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import {
    ChatBlock,
    LabelButton,
    TabBlock,
    WidgetButtonSettings,
    WidgetButtonTypeTwoSettings
} from "../style";
import Typography from "@mui/material/Typography";
import {
    Chat as ChatIcon
} from '@mui/icons-material';
import ButtonBlue from "Blocks/ButtonBlue/ButtonBlue";
import { MuiColorInput } from "mui-color-input";
import HeaderWidget from "Components/HeaderWidget/HeaderWidget";
import FooterWidgetSettings from "Components/FooterWidgetSettings/FooterWidgetSettings";
import ChatWidgetSettings from "Components/ChatWidgetSettings/ChatWidgetSettings";
import { useTranslation } from "react-i18next";
import { useStore } from "stores";
import { observer } from "mobx-react-lite";


type TabWidgetAppearanceT = {
    tab: number,
};

const initialState = {
    startButtonColor: '',
    startButtonBackground: '',
    headerColor: '',
    headerBackground: '',
    footerInputColor: '',
    footerInputBackground: '',
    footerInputPlaceholderColor: '',
    footerIconColor: '',
    footerBackground: '',
    footerCloseTextColor: '',
    backgroundColorBtnClose: '',
    colorBtnClose: '',
    backgroundColorBtnCancel: '',
    colorBtnCancel: '',
    startPageBackground: '',
    startPageColor: '',
    fontSizeStartPage: '',
    textStartPage: '',
    textStartPageEn: '',
    startPageFontWeight: '',
    startPageButtonColor: '',
    startPageButtonBackground: '',
    chatPageBackground: '',
    chatLeftMessageBackground: '',
    chatLeftMessageColor: '',
    chatRightMessageBackground: '',
    chatRightMessageColor: '',
    typeStartPage: 1,
    typeButton: 1,
};

export const TabWidgetAppearance = ({ tab }: TabWidgetAppearanceT) => {
    const { t } = useTranslation('common');
    const { userStore: { data: user, updateSettingsApi } } = useStore();

    const [settings, setSettings] = useState(initialState);

    useEffect(() => {
        setSettings({...settings, ...user?.application.settings})
    }, [user]);

    const updateSettings = () => {
        if (user && user.application.id) {
            const entries = Object.entries(settings).filter(([key,val]) => val !== '' && val !== null);
            const body = {
                settings: Object.fromEntries(entries)
            };
            updateSettingsApi(body, user.application.id)
        }
    };

    return (
        <TabBlock className={tab !== 3 ? 'hide' : ''}>
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                noWrap
            >
                {t('settings.viewWidget.title')}
            </Typography>

            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                }}
            >
                {t('settings.viewWidget.startButton')}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.startButtonBackground}
                        onChange={(value: string) => setSettings({...settings, startButtonBackground: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.startButtonBackground')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.startButtonColor}
                        onChange={(value: string) => setSettings({...settings, startButtonColor: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.startButtonColor')}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <LabelButton
                        onClick={() => setSettings({...settings, typeButton: 1})}
                        className={settings.typeButton === 1 ? 'active' : ''}
                    >
                        <WidgetButtonSettings
                            backgroundColor={settings.startButtonBackground}
                            color={settings.startButtonColor}
                        >
                            <ChatIcon />
                        </WidgetButtonSettings>
                    </LabelButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LabelButton
                        onClick={() => setSettings({...settings, typeButton: 2})}
                        className={settings.typeButton === 2 ? 'active' : ''}
                    >
                        <WidgetButtonTypeTwoSettings
                            backgroundColor={settings.startButtonBackground}
                            color={settings.startButtonColor}
                        >
                            <div className="label">
                                {t('settings.viewWidget.startButtonTypeTwoText')}
                            </div>
                            <WidgetButtonSettings
                                backgroundColor={settings.startButtonBackground}
                                color={settings.startButtonColor}
                            >
                                <ChatIcon />
                            </WidgetButtonSettings>
                        </WidgetButtonTypeTwoSettings>
                    </LabelButton>
                </Grid>
            </Grid>

            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                }}
            >
                Header
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.headerBackground}
                        onChange={(value: string) => setSettings({...settings, headerBackground: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.headerBackground')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.headerColor}
                        onChange={(value: string) => setSettings({...settings, headerColor: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.headerColor')}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <LabelButton
                        className={'active'}
                    >
                        <HeaderWidget
                            backgroundColor={settings.headerBackground}
                            color={settings.headerColor}
                            title="Help chat"
                        />
                    </LabelButton>
                </Grid>
            </Grid>

            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                }}
            >
                Footer
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.footerBackground}
                        onChange={(value: string) => setSettings({...settings, footerBackground: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.footerBackground')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.footerIconColor}
                        onChange={(value: string) => setSettings({...settings, footerIconColor: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.footerIconColor')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.footerInputBackground}
                        onChange={(value: string) => setSettings({...settings, footerInputBackground: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.footerInputBackground')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.footerInputColor}
                        onChange={(value: string) => setSettings({...settings, footerInputColor: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.footerInputColor')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.footerInputPlaceholderColor}
                        onChange={(value: string) => setSettings({...settings, footerInputPlaceholderColor: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.footerInputPlaceholderColor')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.footerCloseTextColor}
                        onChange={(value: string) => setSettings({...settings, footerCloseTextColor: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.footerCloseTextColor')}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.backgroundColorBtnClose}
                        onChange={(value: string) => setSettings({...settings, backgroundColorBtnClose: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.backgroundColorBtnClose')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.colorBtnClose}
                        onChange={(value: string) => setSettings({...settings, colorBtnClose: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.colorBtnClose')}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.backgroundColorBtnCancel}
                        onChange={(value: string) => setSettings({...settings, backgroundColorBtnCancel: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.backgroundColorBtnCancel')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.colorBtnCancel}
                        onChange={(value: string) => setSettings({...settings, colorBtnCancel: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.colorBtnCancel')}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <LabelButton
                        className={'active'}
                    >
                        <FooterWidgetSettings
                            backgroundColor={settings.footerBackground}
                            inputColor={settings.footerInputColor}
                            inputBackground={settings.footerInputBackground}
                            inputInputPlaceholderColor={settings.footerInputPlaceholderColor}
                            iconColor={settings.footerIconColor}
                            footerCloseTextColor={settings.footerCloseTextColor}
                            chat
                        />
                    </LabelButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LabelButton
                        className={'active'}
                    >
                        <FooterWidgetSettings
                            backgroundColor={settings.footerBackground}
                            backgroundColorBtnCancel={settings.backgroundColorBtnCancel}
                            backgroundColorBtnClose={settings.backgroundColorBtnClose}
                            colorBtnCancel={settings.colorBtnCancel}
                            colorBtnClose={settings.colorBtnClose}
                            inputColor={settings.footerInputColor}
                            inputBackground={settings.footerInputBackground}
                            inputInputPlaceholderColor={settings.footerInputPlaceholderColor}
                            iconColor={settings.footerIconColor}
                            closeChat={true}
                        />
                    </LabelButton>
                </Grid>
            </Grid>

            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                }}
            >
                {t('settings.viewWidget.startPageTitle')}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.startPageBackground}
                        onChange={(value: string) => setSettings({...settings, startPageBackground: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.startPageBackground')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.startPageColor}
                        onChange={(value: string) => setSettings({...settings, startPageColor: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.startPageColor')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="start-page-font-weight-label">{t('settings.viewWidget.startPageFontWeight')}</InputLabel>
                        <Select
                            labelId="start-page-font-weight-label"
                            id="demo-simple-select"
                            value={settings.startPageFontWeight}
                            label={t('settings.viewWidget.startPageFontWeight')}
                            onChange={(event: SelectChangeEvent) => setSettings({...settings, startPageFontWeight: event.target.value})}
                        >
                            <MenuItem value={'400'}>{t('settings.viewWidget.fontWeightRegular')}</MenuItem>
                            <MenuItem value={'600'}>{t('settings.viewWidget.fontWeightBold')} (600)</MenuItem>
                            <MenuItem value={'700'}>{t('settings.viewWidget.fontWeightBold')} (700)</MenuItem>
                            <MenuItem value={'800'}>{t('settings.viewWidget.fontWeightBold')} (800)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        type="number"
                        fullWidth
                        id="fontSizeStartPage"
                        label={t('settings.viewWidget.fontSizeStartPage')}
                        name="fontSizeStartPage"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSettings({...settings, fontSizeStartPage: event.target.value})}
                        value={settings.fontSizeStartPage}
                    />
                </Grid>
                {(settings.typeStartPage === 2 || settings.typeStartPage === 3) && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <MuiColorInput
                                value={settings.startPageButtonBackground}
                                onChange={(value: string) => setSettings({...settings, startPageButtonBackground: value})}
                                style={{
                                    width: '100%'
                                }}
                                label={t('settings.viewWidget.startPageButtonBackground')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MuiColorInput
                                value={settings.startPageButtonColor}
                                onChange={(value: string) => setSettings({...settings, startPageButtonColor: value})}
                                style={{
                                    width: '100%'
                                }}
                                label={t('settings.viewWidget.startPageButtonColor')}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t('settings.viewWidget.textStartPage')}
                        multiline
                        maxRows={4}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSettings({...settings, textStartPage: event.target.value})}
                        value={settings.textStartPage}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t('settings.viewWidget.textStartPageEn')}
                        multiline
                        maxRows={4}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSettings({...settings, textStartPageEn: event.target.value})}
                        value={settings.textStartPageEn}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <LabelButton
                        onClick={() => setSettings({...settings, typeStartPage: 1})}
                        className={settings.typeStartPage === 1 ? 'active' : ''}
                    >
                        <ChatBlock>
                            <HeaderWidget
                                backgroundColor={settings.headerBackground}
                                color={settings.headerColor}
                                title="Help chat"
                            />
                            <ChatWidgetSettings
                                fontSizeStartPage={settings.fontSizeStartPage}
                                textStartPage={settings.textStartPage}
                                startPageBackground={settings.startPageBackground}
                                startPageColor={settings.startPageColor}
                                startPageFontWeight={settings.startPageFontWeight}
                            />
                            <FooterWidgetSettings
                                backgroundColor={settings.footerBackground}
                                inputColor={settings.footerInputColor}
                                inputBackground={settings.footerInputBackground}
                                inputInputPlaceholderColor={settings.footerInputPlaceholderColor}
                                iconColor={settings.footerIconColor}
                            />
                        </ChatBlock>
                    </LabelButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LabelButton
                        onClick={() => setSettings({...settings, typeStartPage: 2})}
                        className={settings.typeStartPage === 2 ? 'active' : ''}
                    >
                        <ChatBlock>
                            <HeaderWidget
                                backgroundColor={settings.headerBackground}
                                color={settings.headerColor}
                                title="Help chat"
                            />
                            <ChatWidgetSettings
                                textStartPage={settings.textStartPage}
                                fontSizeStartPage={settings.fontSizeStartPage}
                                startPageBackground={settings.startPageBackground}
                                startPageColor={settings.startPageColor}
                                startPageFontWeight={settings.startPageFontWeight}
                            />
                            <FooterWidgetSettings
                                backgroundColor={settings.footerBackground}
                                inputColor={settings.footerInputColor}
                                inputBackground={settings.footerInputBackground}
                                inputInputPlaceholderColor={settings.footerInputPlaceholderColor}
                                iconColor={settings.footerIconColor}
                                typeTwoStartPage
                                startPageBackground={settings.startPageBackground}
                                startPageButtonBackground={settings.startPageButtonBackground}
                                startPageButtonColor={settings.startPageButtonColor}
                            />
                        </ChatBlock>
                    </LabelButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LabelButton
                        onClick={() => setSettings({...settings, typeStartPage: 3})}
                        className={settings.typeStartPage === 3 ? 'active' : ''}
                    >
                        <ChatBlock>
                            <HeaderWidget
                                backgroundColor={settings.headerBackground}
                                color={settings.headerColor}
                                title="Help chat"
                            />
                            <ChatWidgetSettings
                                textStartPage={settings.textStartPage}
                                startPageBackground={settings.startPageBackground}
                                startPageColor={settings.startPageColor}
                                fontSizeStartPage={settings.fontSizeStartPage}
                                startPageFontWeight={settings.startPageFontWeight}
                            />
                            <FooterWidgetSettings
                                backgroundColor={settings.footerBackground}
                                inputColor={settings.footerInputColor}
                                inputBackground={settings.footerInputBackground}
                                inputInputPlaceholderColor={settings.footerInputPlaceholderColor}
                                iconColor={settings.footerIconColor}
                                typeTreeStartPage
                                startPageBackground={settings.startPageBackground}
                                startPageButtonBackground={settings.startPageButtonBackground}
                                startPageButtonColor={settings.startPageButtonColor}
                            />
                        </ChatBlock>
                    </LabelButton>
                </Grid>
            </Grid>

            <Typography
                component="div"
                color="inherit"
                sx={{
                    mt: 1,
                    mb: 1,
                }}
            >
                {t('settings.viewWidget.chatPageTitle')}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.chatPageBackground}
                        onChange={(value: string) => setSettings({...settings, chatPageBackground: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.chatPageBackground')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.chatLeftMessageBackground}
                        onChange={(value: string) => setSettings({...settings, chatLeftMessageBackground: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.chatLeftMessageBackground')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.chatLeftMessageColor}
                        onChange={(value: string) => setSettings({...settings, chatLeftMessageColor: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.chatLeftMessageColor')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.chatRightMessageBackground}
                        onChange={(value: string) => setSettings({...settings, chatRightMessageBackground: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.chatRightMessageBackground')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiColorInput
                        value={settings.chatRightMessageColor}
                        onChange={(value: string) => setSettings({...settings, chatRightMessageColor: value})}
                        style={{
                            width: '100%'
                        }}
                        label={t('settings.viewWidget.chatRightMessageColor')}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <LabelButton className={'active'} >
                        <ChatBlock>
                            <HeaderWidget
                                backgroundColor={settings.headerBackground}
                                color={settings.headerColor}
                                title="Help chat"
                            />
                            <ChatWidgetSettings
                                chat
                                chatPageBackground={settings.chatPageBackground}
                                chatLeftMessageBackground={settings.chatLeftMessageBackground}
                                chatLeftMessageColor={settings.chatLeftMessageColor}
                                chatRightMessageBackground={settings.chatRightMessageBackground}
                                chatRightMessageColor={settings.chatRightMessageColor}
                            />
                            <FooterWidgetSettings
                                backgroundColor={settings.footerBackground}
                                inputColor={settings.footerInputColor}
                                inputBackground={settings.footerInputBackground}
                                inputInputPlaceholderColor={settings.footerInputPlaceholderColor}
                                iconColor={settings.footerIconColor}
                                chat
                            />
                        </ChatBlock>
                    </LabelButton>
                </Grid>
            </Grid>
            <ButtonBlue title={t('settings.save')} onClick={updateSettings} />
        </TabBlock>
    );
};

export default observer(TabWidgetAppearance);
