import resources from './resources';

export const config = {
    interpolation: { escapeValue: false },
    lng: 'en',
    resources,
    react: {
        useSuspense: false,
    },
};

export { resources };
