import * as React from 'react';
import {Badge, Drawer as MuiDrawer, IconButton, ListItemText, Toolbar} from "@mui/material";
import {MenuItem, SidebarBlock} from "./style";
import MenuIcon from "@mui/icons-material/Menu";
import HistoryIcon from '@mui/icons-material/History';
import {styled} from "@mui/material/styles";
import {GlobalThemeSettings} from "Utils/GlobalThemeSettings";
import { Chat as ChatIcon, Settings as SettingsIcon, Person} from "@mui/icons-material";
import {useSocket} from "Hooks/useSocket";
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import * as Pathname from "Utils";
import {useTranslation} from "react-i18next";
import {useStore} from "stores";
import {observer} from "mobx-react-lite";

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export const Sidebar = observer(() => {
    const { t } = useTranslation('common');
    const location = useLocation();
    const {
        chatStore: {
            setNewMessageCount,
        },
        userStore: {
            data: userState,
        }
    } = useStore();
    const {
        joinListeningMessages,
        notifications,
    } = useSocket();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        joinListeningMessages(Number(localStorage.getItem('appId')))
    }, []);

    useEffect(() => {
        userState?.appOperator.map((el) => {
            console.log('el.id', el.id);
            joinListeningMessages(el.id);
        })
    }, [userState]);

    useEffect(() => {
        setNewMessageCount(notifications);
    }, [notifications]);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Drawer
            PaperProps={{
                sx: {
                    backgroundColor: GlobalThemeSettings.color.sidebar,
                    width: '64px',
                    borderRadius: '15px',
                    margin: '10px',
                    marginRight: 0,
                    height: 'calc(100% - 20px)',
                }
            }}
            variant="permanent"
            open={open}
        >
            <Toolbar
                sx={{
                    px: [1.5],
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <MenuIcon style={{
                        color: "white"
                    }}/>
                </IconButton>
            </Toolbar>
            <SidebarBlock>
                <div>
                    <Link to={Pathname.chat}>
                        <MenuItem
                            className={
                                location.pathname.includes(Pathname.chat) ? 'active' : ''
                            }
                        >
                            <Badge badgeContent={notifications} color="primary" anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}>
                                <ChatIcon />
                            </Badge>
                            <ListItemText primary={t('sidebar.chat')} />
                        </MenuItem>
                    </Link>
                    <Link to={Pathname.archive}>
                        <MenuItem
                            className={
                                location.pathname.includes(Pathname.archive) ? 'active' : ''
                            }
                        >
                            <HistoryIcon />
                            <ListItemText primary={t('sidebar.archive')} />
                        </MenuItem>
                    </Link>
                    {localStorage.getItem('myApplicationId') === localStorage.getItem('appId') && (
                        <Link to={Pathname.users}>
                            <MenuItem
                                className={
                                    location.pathname.includes(Pathname.users) ? 'active' : ''
                                }
                            >
                                <Person />
                                <ListItemText primary={t('sidebar.user')} />
                            </MenuItem>
                        </Link>
                    )}
                </div>
                <div>
                    <Link to={Pathname.settings}>
                        <MenuItem
                            className={
                                location.pathname.includes(Pathname.settings) ? 'active' : ''
                            }
                        >
                            <SettingsIcon />
                            <ListItemText primary={t('sidebar.settings')} />
                        </MenuItem>
                    </Link>
                </div>
            </SidebarBlock>
        </Drawer>
    );
})

export default Sidebar;