/* eslint-disable camelcase */
import common_en from './en/common.json';
import common_ua from './ua/common.json';

export default {
    en: {
        common: common_en,
    },
    ua: {
        common: common_ua,
    },
};
