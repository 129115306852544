import React, {useEffect} from 'react';
import {
    Paper,
    Grid,
    Box,
    ThemeProvider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {createTheme} from "@mui/material/styles";
import Sidebar from "Components/Sidebar/Sidebar";
import Header from "Components/Header/Header";
import {Outlet} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: 'calc(100% - 96px)',
        boxShadow: 'none !important',
    },
});
const mdTheme = createTheme();

type MainWrapperT = {
    children?: React.ReactNode;
};

export const MainWrapper: React.FC<MainWrapperT> = ({children}) => {
    const { t } = useTranslation('common');
    const { pathname } = useLocation();
    const classes = useStyles();

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <Sidebar />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Header title={t(`title.${pathname.split('/')[1]}`)} />
                    <Grid container component={Paper} className={classes.chatSection}>
                        {children ? children : (
                            <Outlet />
                        )}
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default MainWrapper;
