import {makeAutoObservable, runInAction} from 'mobx';
import { ApiServicePromise } from "Utils/ApiService";

type ErrorStatusT = {
    status: boolean,
    message: string,
}
type ErrorT = {
    [key: string]: ErrorStatusT;
}

type DeviceT = {
    id: number,
    token: string,
    createdAt: Date,
    device: object,
    name: string | number,
}

type RoomT = {
    id: number,
    device: DeviceT,
    isClose: boolean,
    notReaded: number,
}

export default class RoomState {
    isLoading = true;
    rooms?: RoomT[];
    selectRoom?: RoomT | null;
    error?: ErrorT;

    lastAbortController: any;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setSelected(id: number | null) {
        let selected = null;
        if (id) {
            selected = this.rooms?.find((el) => el.id === id) || null;
        }
        this.selectRoom = selected;
    }

    setError(error: ErrorT) {
        this.error = error;
    }

    getRooms(isArchive = false) {
        this.isLoading = true;

        if (this.lastAbortController) {
            this.lastAbortController.abort();
        }

        const abortController = new AbortController();
        this.lastAbortController = abortController;

        let endPoint = 'chat/get-chats';
        if (isArchive) {
            endPoint = 'chat/get-archive';
        }
        ApiServicePromise({
            endPoint,
            method: 'GET',
            headers: {'application': localStorage.getItem('appId')},
            signal: abortController.signal,
        }).then((res) => {
            if (abortController.signal.aborted) {
                return new Promise(() => {});
            }
            runInAction(() => {
                this.rooms = res;
                this.isLoading = false;
            });
        });
    }

    closeChat(id: number, appToken: string) {
        ApiServicePromise({
            endPoint: 'chat/close',
            method: 'POST',
            body: {
                userToken: appToken,
                roomId: id,
            }
        }).then((res) => {
            runInAction(() => {
                this.rooms = this.rooms?.filter((el) => el.id !== id);
                this.selectRoom = null;
            });
        });
    }
}
