import styled from 'styled-components'
import {GlobalThemeSettings} from "Utils/GlobalThemeSettings";

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
  transition: 0.3s;
  text-align: left;
  cursor: pointer;
  svg {
    margin-right: 20px;
  }
  &:hover {
    background-color: ${GlobalThemeSettings.color.sidebar};
    color: white;
  }
  &.active {
    background-color: ${GlobalThemeSettings.color.blue};
    color: white;
  }
`;