import React from 'react';
import {MessageRight, MessageLeft, MessagesBlock, MessagesBlockDay, WelcomeMessage} from "./style";
import {useTranslation} from "react-i18next";

interface ChatWidgetSettingsT {
    chat?: boolean;
    startPageBackground?: string;
    startPageColor?: string;
    startPageFontWeight?: string;
    chatLeftMessageBackground?: string;
    chatLeftMessageColor?: string;
    chatRightMessageBackground?: string;
    chatRightMessageColor?: string;
    chatPageBackground?: string;
    fontSizeStartPage?: string;
    textStartPage?: string,
}

export const ChatWidgetSettings =
    ({
         chat,
         startPageBackground,
         startPageColor,
         startPageFontWeight,
         chatLeftMessageBackground,
         chatLeftMessageColor,
         chatRightMessageBackground,
         chatRightMessageColor,
         chatPageBackground,
         fontSizeStartPage,
         textStartPage,
    }: ChatWidgetSettingsT) => {
    const { t } = useTranslation('common');
    return (
        <>
            {chat ? (
                <MessagesBlock
                    backgroundColor={chatPageBackground}
                >
                    <MessagesBlockDay>
                        <MessageRight
                            backgroundColor={chatRightMessageBackground}
                            color={chatRightMessageColor}
                        >
                            {t('widget.messageExampleOne')}
                        </MessageRight>
                        <MessageLeft
                            backgroundColor={chatLeftMessageBackground}
                            color={chatLeftMessageColor}
                        >
                            {t('widget.messageExampleTwo')}
                        </MessageLeft>
                    </MessagesBlockDay>
                </MessagesBlock>
            ) : (
                <WelcomeMessage
                    backgroundColor={startPageBackground}
                    color={startPageColor}
                    fontWeight={startPageFontWeight}
                    fontSize={fontSizeStartPage}
                >
                    <p>
                        {textStartPage || 'Маєте питання? Наша команда завжди готова зарадити!'}
                    </p>
                </WelcomeMessage>
            )}
        </>
    );
};

export default ChatWidgetSettings;