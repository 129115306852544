import React, { useEffect, useState } from 'react';
import {
    Paper,
    Grid,
    Box,
    ThemeProvider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme } from "@mui/material/styles";
import Header from "Components/Header/Header";
import MessageTextEditorSimple from "Components/MessageTextEditorSimple/MessageTextEditorSimple";
import ChatBlock from "Components/ChatBlock/ChatBlock";
import { СhatArea } from "./style";
import { useSocket } from "Hooks/useSocket";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStore } from "stores";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: 'calc(100% - 96px)',
        boxShadow: 'none !important',
    },
});

const mdTheme = createTheme();

interface ChatBotT {
    id: string
}

export const ChatBot = observer(({id}: ChatBotT) => {
    const { t } = useTranslation('common');
    const {
        userStore: {
            data: user,
        },
        chatStore: {
            getChatApplication,
            clearMessages,
        }
    } = useStore();
    const [connectAppId, setConnectAppId] = useState(false);
    const classes = useStyles();
    const {
        sendMessage,
        joinRoom,
    } = useSocket();

    useEffect(() => {
        return () => {
            clearMessages();
        }
    }, []);

    useEffect(() => {
        if (user?.application.id) {
            getChatApplication(id);
            setConnectAppId(true);
        }
    }, [user]);

    useEffect(() => {
        if (user?.application.id && connectAppId) {
            joinRoom({
                applicationId: user.application.id,
                chatId: Number(id),
            });
        }
    }, [connectAppId]);

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Header title={t('title.chat')} isChatBot />
                    <Grid container component={Paper} className={classes.chatSection}>
                        <Grid item xs={12}>
                            <СhatArea>
                                <ChatBlock />
                                <Grid container style={{padding: '10px 0'}}>
                                    <MessageTextEditorSimple
                                        sendMessage={sendMessage}
                                        applicationId={user?.application.id}
                                        chatId={Number(id)}
                                    />
                                </Grid>
                            </СhatArea>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    );
});

export default ChatBot;
