import React, { useState } from 'react';
import {
    Grid,
} from "@mui/material";
import SettingsSidebar from "Components/SettingsSidebar/SettingsSidebar";
import {
    SettingsArea,
} from "./style";
import { useTranslation } from "react-i18next";
import TabProfile from "./Tabs/TabProfile";
import TabWidget from "./Tabs/TabWidget";
import TabWidgetAppearance from "./Tabs/TabWidgetAppearance";
import MainWrapper from "../../Components/MainWrapper/MainWrapper";

export const Settings = () => {
    const { t } = useTranslation('common');
    const [tab, setTab] = useState<number>(1);

    return (
        <>
            <SettingsSidebar items={localStorage.getItem('myApplicationId') === localStorage.getItem('appId') ? [
                {
                    id: 1,
                    name: t('settings.sidebar.profile'),
                    setTab: () => setTab(1),
                },
                {
                    id: 2,
                    name: t('settings.sidebar.widget'),
                    setTab: () => setTab(2),
                },
                {
                    id: 3,
                    name: t('settings.sidebar.viewWidget'),
                    setTab: () => setTab(3),
                }
            ] : [
                {
                    id: 1,
                    name: t('settings.sidebar.profile'),
                    setTab: () => setTab(1),
                },
            ]} tab={tab} />
            <Grid item xs={12} sm={9}>
                <SettingsArea>
                    <TabProfile tab={tab} />
                    {localStorage.getItem('myApplicationId') === localStorage.getItem('appId') && (
                        <>
                            <TabWidget tab={tab} />
                            <TabWidgetAppearance tab={tab} />
                        </>
                    )}
                </SettingsArea>
            </Grid>
        </>
    );
};

export default Settings;
