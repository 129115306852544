export interface SizeAndLineHeight {
    [key: string]: string;
}

export const Color: SizeAndLineHeight = {
    text: '#333333',
    white: '#FFFFFF',
    textLight: '#828282',
    sidebar: '#809fff',
    darkBlue: '#001f7d',
    blue: '#0000b3',
    danger: '#FF5733',
};

export const GlobalThemeSettings = {
    color: Color,
};
