import styled from 'styled-components'
import {GlobalThemeSettings} from "../../Utils/GlobalThemeSettings";

type InputRowSettings  ={
    backgroundColor?: string;
    iconColor?: string
}

type TextWrapperSettings  ={
    backgroundColor?: string;
    color?: string
    placeholderColor?: string
}


export const InputRow = styled.div<InputRowSettings>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 5px;
  width: -webkit-fill-available;
  background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.white};
  
  svg {
    fill: ${props => props.iconColor || '#858585'};
  }
`;

export const InputColumn = styled(InputRow)`
  flex-direction: column;
`;


export const TextAreaWrapper = styled.div<TextWrapperSettings>`
  background-color: ${props => props.backgroundColor || '#f2f2f2'};
  padding: 5px 17px 5px;
  border-radius: 18px;
  width: calc(100% - 155px);
`;

export const TextInputWrapper = styled(TextAreaWrapper)`
  width: calc(100% - 75px);
`;

export const TextArea = styled.textarea<TextWrapperSettings>`
  border: none;
  font-size: 16px;
  line-height: 150%;
  overflow-y: auto;
  overflow-wrap: break-word;
  resize: none;
  width: 100%;
  max-height: 115px;
  background-color: ${props => props.backgroundColor || '#f2f2f2'};
  color: ${props => props.color || 'rgb(51, 51, 51)'};
  outline: none;
  &::placeholder {
    color: ${props => props.placeholderColor || '#a9a9a9'};
  }
  //min-height: 28px;
`;

export const InputName = styled.input<TextWrapperSettings>`
  border: none;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  outline: none;
  height: 28px;
  background-color: ${props => props.backgroundColor || '#f2f2f2'};
  color: ${props => props.color || 'rgb(51, 51, 51)'};
  &::placeholder {
    color: ${props => props.placeholderColor || '#a9a9a9'};
  }
`;

export const EmojiWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  border: 1px solid rgb(239, 239, 239);
  border-radius: 5px;
  box-shadow: rgb(239 239 239) 0px 5px 10px;
`;


export const ButtonChat = styled.button`
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  &:hover {
    background-color: rgb(128,128,128, 0.1);
  }
`

export const UsernameGroup = styled.div<TextWrapperSettings>`
  background-color: ${props => props.backgroundColor || '#f3f5f8'};
  display: flex;
  flex-direction: column;
  padding: 10px;
  div {
    width: -webkit-fill-available;
  }
`;