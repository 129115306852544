import styled from 'styled-components'
import {GlobalThemeSettings} from "Utils/GlobalThemeSettings";

type WidgetHeaderColor = {
    backgroundColor?: string;
    color?: string;
}

export const HeaderBlock = styled.header<WidgetHeaderColor>`
  min-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
  color: ${props => props.color || GlobalThemeSettings.color.white};

  button {
    height: 30px;
    width: 30px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    color: ${props => props.color || GlobalThemeSettings.color.white};
    &:hover {
      background-color: rgb(128,128,128, 0.1);
    }
  }
`
