import React, {useEffect, useState} from 'react';
import {
    Grid,
    Box,
    TextField,
    Button,
} from '@mui/material';
import {useTranslation} from "react-i18next";
import ButtonBlue from "Blocks/ButtonBlue/ButtonBlue";
import {
    ButtonGroup,
} from "../style";
import Typography from "@mui/material/Typography";
import {GlobalThemeSettings} from "Utils/GlobalThemeSettings";
import {DeleteOutline} from '@mui/icons-material';
import ValidateForm from "Utils/ValidateForm";
import {useStore} from "stores";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

type ErrorStatusT = {
    status: boolean,
    message: string,
}
type ErrorT = {
    [key: string]: ErrorStatusT;
}

const initError: ErrorT = {
    email: {
        status: false,
        message: '',
    },
    lastName: {
        status: false,
        message: '',
    },
    firstName: {
        status: false,
        message: '',
    },
    nameCompany: {
        status: false,
        message: '',
    },
    password: {
        status: false,
        message: '',
    },
}

type FormOperatorT = {
    id?: number;
}

export const FormOperator = observer(({ id }: FormOperatorT) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('common');
    const {
        operatorStore: {
            error,
            setError,
            notUser,
            setNotUser,
            removeOperatorApi,
            registerOperatorApi,
            addOperatorApi,
            currentOperator,
            operators,
            setOperator,
            isLoading,
        }
    } = useStore();

    const [email, setEmail] = useState('');

    useEffect(() => {
        if (!isLoading) {
            if (currentOperator && currentOperator.id !== id) {
                navigate(`/users/${currentOperator.id}`)
            }
            if (!currentOperator && id) {
                const operatorFind = operators && operators.find((el) => el.id === Number(id));
                if (operatorFind) {
                    setOperator(operatorFind);
                } else {
                    navigate(`/users`)
                }
            }
        }
    }, [id, currentOperator, operators, isLoading]);

    const addOperator = () => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            addOperatorApi(email)
            setError(initError);
        } else {
            setError({
                email: {
                    status: true,
                    message: t('users.incorrectEmail') as string,
                },
            });
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (notUser) {
            const validate = ValidateForm(data, i18n.language);
            setError({...validate.error});
            if (!validate.isError) {
                registerOperatorApi({
                    email: data.get('email') as string,
                    lastName: data.get('lastName') as string,
                    firstName: data.get('firstName') as string,
                    password: data.get('password') as string,
                });
            }
        } else {
            addOperator();
        }
    };

    const removeOperator = () => {
        if (id) {
            removeOperatorApi(id);
        }
    }

    return (
        <>
            {id ? (
                <div>
                    <Typography
                        component="h2"
                        variant="h6"
                        color="inherit"
                        noWrap
                    >
                        {t('users.operatorTitle')}
                    </Typography>
                    <Typography
                        component="div"
                        color="inherit"
                        sx={{
                            mt: 1,
                            mb: 1,
                        }}
                    >
                        {t('users.operatorProfileTitle')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                disabled
                                value={currentOperator?.email || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('settings.profile.inputName')}
                                disabled
                                value={currentOperator?.firstName || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('settings.profile.inputLastName')}
                                disabled
                                value={currentOperator?.lastName || ''}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="button"
                        variant="contained"
                        sx={[
                            {
                                '&:hover': {
                                    backgroundColor: GlobalThemeSettings.color.danger,
                                    boxShadow: 'none',
                                },
                            },
                            {
                                boxShadow: 'none',
                                borderRadius: '15px',
                                mt: 2,
                                mb: 2,
                                ml: 0,
                                p: 1.5,
                                pl: 0,
                                pr: 0,
                                backgroundColor: GlobalThemeSettings.color.danger,
                            }
                        ]}
                        onClick={() => removeOperator()}
                    >
                        <DeleteOutline />
                    </Button>
                </div>
            ) : (
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Typography
                        component="h2"
                        variant="h6"
                        color="inherit"
                        noWrap
                    >
                        {t('users.addOperatorTitle')}
                    </Typography>
                    <Typography
                        component="div"
                        color="inherit"
                        sx={{
                            mt: 1,
                            mb: 1,
                        }}
                    >
                        {t('users.emailTitle')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                type="email"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                                value={email}
                                error={error.email.status || false}
                                helperText={error.email.message || ''}
                            />
                        </Grid>
                    </Grid>
                    {notUser ? (
                        <>
                            <Typography
                                component="div"
                                color="inherit"
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                }}
                            >
                                {t('users.reqTitle')}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        id="firstName"
                                        label={t('settings.profile.inputName')}
                                        name="firstName"
                                        error={error.firstName.status || false}
                                        helperText={error.firstName.message || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        id="lastName"
                                        label={t('settings.profile.inputLastName')}
                                        name="lastName"
                                        error={error.lastName.status || false}
                                        helperText={error.lastName.message || ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        label={t('settings.profile.inputPassword')}
                                        name="password"
                                        type="password"
                                        autoComplete="off"
                                        error={error.password.status || false}
                                        helperText={error.password.message || ''}
                                    />
                                </Grid>
                            </Grid>
                            <ButtonGroup>
                                <ButtonBlue title={t('users.backButton')} onClick={() => setNotUser(false)} type="button" />
                                <ButtonBlue title={t('settings.save')} type="submit" />
                            </ButtonGroup>
                        </>
                    ) : (
                        <ButtonBlue title={t('users.nextButton')} type="submit" />
                    )}
                </Box>
            )}
        </>
    );
});

export default FormOperator;
