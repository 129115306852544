import {ajax} from "rxjs/ajax";
import {catchError, map} from "rxjs/operators";
import {Observable, of, throwError} from "rxjs";
import * as Pathname from "./index";
import {checkLocalStorage} from "./index";

interface ApiServiceT {
    endPoint: string,
    method: string,
    body?: any,
    headers?: object,
    signal?: AbortSignal | null,
}

export const ApiService = (
    {
        endPoint,
        method = 'GET',
        body,
        headers
    }: ApiServiceT
): Observable<any> => {
    let token = null;
    if (checkLocalStorage()) {
        token = localStorage.getItem('jwt_token');
    }

    const baseUrl =
        process.env.REACT_APP_SERVER_URL || 'https://api.helpchat.faraday.uno';

    const apiBase = baseUrl + '/';
    const headersData = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...headers
    };

    return ajax({
        url: apiBase + endPoint,
        method,
        headers: headersData,
        body,
    }).pipe(
        catchError((err) => {
            if (err.response.message === 'Unauthorized') {
                localStorage.removeItem('jwt_token');
                localStorage.clear();
                window.location.href = Pathname.login;
            }
            return throwError(() => err.response.message)
        })
    );
}

export const ApiServicePromise = (
    {
        endPoint,
        method = 'GET',
        body,
        headers,
        signal = null,
    }: ApiServiceT
): Promise<any> => {
    let token = null;
    if (checkLocalStorage()) {
        token = localStorage.getItem('jwt_token');
    }
    const baseUrl =
        process.env.REACT_APP_SERVER_URL || 'https://api.helpchat.faraday.uno';

    const apiBase = baseUrl + '/';
    const headersData = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...headers
    };
    return fetch(apiBase + endPoint, {
        method,
        headers: headersData,
        body: JSON.stringify(body),
        signal
    })
        .then((res) => {
            if (res.status === 401) {
                localStorage.removeItem('jwt_token');
                localStorage.clear();
                window.location.href = Pathname.login;
            }
            return res;
        })
        .then((res) => res.json())
}