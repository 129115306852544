import React from 'react';
import { Close } from '@mui/icons-material';
import {
    HeaderBlock,
} from './style'
import {useTranslation} from "react-i18next";

interface propsT {
    setOpen?: () => void;
    backgroundColor?: string;
    color?: string;
    title: string;
}

export const HeaderWidget = ({setOpen, backgroundColor, color, title}: propsT) => {
    const { t } = useTranslation('common');
    return (
        <HeaderBlock
            backgroundColor={backgroundColor}
            color={color}
        >
            <strong>{t('widget.team')} {title}</strong>
            <button
                onClick={setOpen}
            >
                <Close />
            </button>
        </HeaderBlock>
    );
};
export default HeaderWidget;