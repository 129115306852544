import React, {useEffect} from 'react';
import {
    SidebarUsersBlock,
    SidebarUsersItem,
} from "./style";
import {useNavigate} from "react-router-dom";
import {useStore} from "stores";
import {observer} from "mobx-react-lite";
import {ButtonArea} from "../../Page/Users/style";
import {ListItem} from "@mui/material";
import ButtonBlue from "../../Blocks/ButtonBlue/ButtonBlue";
import {useTranslation} from "react-i18next";

type OperatorsMenuT = {
    id?: number;
}

export const OperatorsMenu = observer(({ id }: OperatorsMenuT) => {
    const { t } = useTranslation('common');
    const {
        operatorStore: {operators, getOperators, setOperator}
    } = useStore();
    const navigate = useNavigate();

    useEffect(() => {
        const appId = localStorage.getItem('appId');
        if (appId) {
            getOperators(appId);
        }
    }, []);

    const openUser = (userId: number) => {
        if (operators) {
            const operator = operators.find((el) => el.id === userId);
            if (operator) {
                setOperator(operator);
                navigate(`/users/${userId}`)
            }
        }
    }
    const openAddUser = () => {
        setOperator(null);
        navigate(`/users`);
    }

    return (
        <>
            <ButtonArea>
                <ListItem key="RemySharp">
                    <ButtonBlue
                        title={t('users.addOperatorButton')}
                        onClick={openAddUser}
                    />
                </ListItem>
            </ButtonArea>
            {operators &&
            operators?.length > 0 && (
                <SidebarUsersBlock>
                    {operators?.map((el) => (
                        <SidebarUsersItem
                            onClick={() => openUser(el.id)}
                            className={el.id === Number(id) ? 'active' : ''}
                            key={el.id}
                        >
                            {el.firstName} {el.lastName}
                        </SidebarUsersItem>
                    ))}
                </SidebarUsersBlock>
            )}
        </>
    );
});

export default OperatorsMenu;
