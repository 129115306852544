import React, { useState } from 'react';
import {Send} from '@mui/icons-material';
import {
    InputName,
    InputRow,
    TextInputWrapper,
    ButtonChat,
    UsernameGroup
} from "./style";
import {Button} from "@mui/material";
import {GlobalThemeSettings} from "../../Utils/GlobalThemeSettings";
import {useTranslation} from "react-i18next";

interface ChatInputSettingsT {
    backgroundColor: string;
    inputColor: string;
    inputBackground: string;
    inputInputPlaceholderColor: string;
    typeTwoStartPage?: boolean;
    typeTreeStartPage?: boolean;
    startPageBackground?: string;
    startPageButtonColor?: string;
    startPageButtonBackground?: string;
}

export const UsernameInputSettings =
    ({
         backgroundColor,
         inputColor,
         inputBackground,
         inputInputPlaceholderColor,
         typeTwoStartPage,
         typeTreeStartPage,
         startPageBackground,
         startPageButtonColor,
         startPageButtonBackground,
    }: ChatInputSettingsT) => {
    const { t } = useTranslation('common');
    const [name, setName] = useState<string>('');

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
    };

    return (
        <footer>
            {(!typeTwoStartPage && !typeTreeStartPage) ? (
                <InputRow
                    backgroundColor={backgroundColor}
                >
                    <TextInputWrapper
                        backgroundColor={inputBackground}
                    >
                        <InputName
                            backgroundColor={inputBackground}
                            color={inputColor}
                            placeholderColor={inputInputPlaceholderColor}

                            value={name}
                            onChange={handleChange}
                            placeholder={`${t('widget.namePlaceholder')}`}
                        />
                    </TextInputWrapper>
                    <ButtonChat>
                        <Send />
                    </ButtonChat>
                </InputRow>
            ) : (
                <UsernameGroup
                    backgroundColor={startPageBackground}
                >
                    {!typeTreeStartPage && (
                        <TextInputWrapper
                            backgroundColor={inputBackground}
                        >
                            <InputName
                                backgroundColor={inputBackground}
                                color={inputColor}
                                placeholderColor={inputInputPlaceholderColor}

                                value={name}
                                onChange={handleChange}
                                placeholder={`${t('widget.namePlaceholder')}`}
                            />
                        </TextInputWrapper>
                    )}
                    <Button
                        type="button"
                        variant="contained"
                        fullWidth={true}
                        sx={[
                            {
                                '&:hover': {
                                    backgroundColor: startPageButtonBackground ? startPageButtonBackground : GlobalThemeSettings.color.blue,
                                    color: startPageButtonColor ? startPageButtonColor : GlobalThemeSettings.color.white,
                                    boxShadow: 'none',
                                },
                            },
                            {
                                boxShadow: 'none',
                                borderRadius: '15px',
                                mt: 1.5,
                                p: 1.5,
                                pl: 4,
                                pr: 4,
                                backgroundColor: startPageButtonBackground ? startPageButtonBackground : GlobalThemeSettings.color.blue,
                                color: startPageButtonColor ? startPageButtonColor : GlobalThemeSettings.color.white,
                            }
                        ]}
                    >
                        {t('widget.startButton')}
                    </Button>
                </UsernameGroup>
            )}
        </footer>
    );
};
export default UsernameInputSettings;