import styled from 'styled-components'
import {GlobalThemeSettings} from "Utils/GlobalThemeSettings";

type ColorT  ={
    backgroundColor?: string;
    color?: string
    placeholderColor?: string
    iconColor?: string
    backgroundColorBtnClose?: string;
    colorBtnClose?: string;
    backgroundColorBtnCancel?: string;
    colorBtnCancel?: string;
}

export const InputRow = styled.div<ColorT>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 5px;
  width: -webkit-fill-available;
  background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.white};
  svg {
    fill: ${props => props.iconColor || '#858585'};
  }
`;

export const InputColumn = styled(InputRow)`
  flex-direction: column;
`;

export const ChatIsCloseBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    margin: 5px;
    font-weight: 500;
  }
`;

export const GroupClose = styled.div<ColorT>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 15px 5px;
  width: -webkit-fill-available;
  background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.white};
  .button-close {
    background-color: ${props => props.backgroundColorBtnClose || GlobalThemeSettings.color.blue};
    color: ${props => props.colorBtnClose || GlobalThemeSettings.color.white};
    &:hover {
      background-color: ${props => props.backgroundColorBtnClose || GlobalThemeSettings.color.blue};
      color: ${props => props.colorBtnClose || GlobalThemeSettings.color.white};
    }
  }
  .button-cancel {
    background-color: ${props => props.backgroundColorBtnCancel || GlobalThemeSettings.color.sidebar};
    color: ${props => props.colorBtnCancel || GlobalThemeSettings.color.white};
    &:hover {
      background-color: ${props => props.backgroundColorBtnCancel || GlobalThemeSettings.color.sidebar};
      color: ${props => props.colorBtnCancel || GlobalThemeSettings.color.white};
    }
  }
  button {
    margin: 5px;
  }
`;

export const ButtonCloseBlock = styled.div<ColorT>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.white};
`;

export const ButtonClose = styled.button<ColorT>`
  background: none;
  border: none;
  text-align: left;
  margin: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  color: ${props => props.color || GlobalThemeSettings.color.text};
`;

export const TextAreaWrapper = styled.div<ColorT>`
  background-color: ${props => props.backgroundColor || '#f2f2f2'};
  padding: 5px 17px 5px;
  border-radius: 18px;
  width: calc(100% - 155px);
  &.record-wrapper {
    width: -webkit-fill-available;
  }
  audio {
    width: 100%;
    height: 28px;
    padding-top: 2px;
  }
`;

export const TextInputWrapper = styled(TextAreaWrapper)`
  width: calc(100% - 75px);
`;

export const TextArea = styled.textarea<ColorT>`
  border: none;
  font-size: 16px;
  line-height: 150%;
  overflow-y: auto;
  overflow-wrap: break-word;
  resize: none;
  width: 100%;
  max-height: 115px;
  outline: none;
  background-color: ${props => props.backgroundColor || '#f2f2f2'};
  color: ${props => props.color || 'rgb(51, 51, 51)'};
  &::placeholder {
    color: ${props => props.placeholderColor || '#a9a9a9'};
  }
  &:has(audio) {
    background: none;
  }
`;

export const InputName = styled.input<ColorT>`
  border: none;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  outline: none;
  height: 28px;

  background-color: ${props => props.backgroundColor || '#f2f2f2'};
  color: ${props => props.color || 'rgb(51, 51, 51)'};
  &::placeholder {
    color: ${props => props.placeholderColor || '#a9a9a9'};
  }
`;

export const EmojiWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  border: 1px solid rgb(239, 239, 239);
  border-radius: 5px;
  box-shadow: rgb(239 239 239) 0px 5px 10px;
`;


export const ButtonChat = styled.button`
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  label {
    cursor: pointer;
  }
  &:hover {
    background-color: rgb(128,128,128, 0.1);
  }
`

export const UsernameGroup = styled.div<ColorT>`
  background-color: ${props => props.backgroundColor || '#f3f5f8'};
  display: flex;
  flex-direction: column;
  padding: 10px;
  div {
    width: -webkit-fill-available;
  }
`;

export const ErrorBlock = styled.div<ColorT>`
  margin: 5px 20px;
  font-size: 14px;
  color: red;
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 40px);
`;