import React from 'react';
import { Grid, ListItemText } from "@mui/material";
import {makeStyles} from "@mui/styles";
import {MenuItem} from "./style";

const useStyles = makeStyles({
    materialArea: {
        borderRadius: '15px !important',
        boxShadow: 'none !important',
        border: '1px solid #E7EBF0 !important',
        margin: '10px !important',
        marginTop: '0 !important',
        marginRight: '0 !important',
    },
});

interface SettingsItemsT {
    id: number
    name: string,
    setTab: () => void;
}

interface SettingsPropT {
    items: SettingsItemsT[],
    tab: number,
}

export const SettingsSidebar = ({ items, tab }: SettingsPropT) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={3}>
            <div className={classes.materialArea}>
                {items?.map((el) => (
                    <MenuItem
                        onClick={el.setTab}
                        className={el.id === tab ? 'active' : ''}
                        key={el.id}
                    >
                        <ListItemText primary={el.name}>{el.name}</ListItemText>
                    </MenuItem>
                ))}
            </div>
        </Grid>
    );
}

export default SettingsSidebar;
