import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Grid,
    Paper,
} from '@mui/material';
import Chart from './Chart';
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const mdTheme = createTheme();

const useStyles = makeStyles({
    materialArea: {
        borderRadius: '15px !important',
        // backgroundColor: '#f8fafd !important',
        boxShadow: 'none !important',
        border: '1px solid #E7EBF0 !important'
    },
});

export const Dashboard = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex',  }}>
                <Sidebar />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Header title={t('title.archive')} />
                    <Grid sx={{ m: 2 }}>
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 240,
                                }}
                                className={classes.materialArea}
                            >
                                <Chart />
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default Dashboard;
