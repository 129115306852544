import React, {ReactNode} from 'react';
import { Button } from "@mui/material";
import {GlobalThemeSettings} from "../../Utils/GlobalThemeSettings";

interface ButtonBluePropsT {
    title: string | ReactNode,
    fullWidth?: boolean,
    type?: 'submit' | 'button',
    onClick?: () => void,
}

export const ButtonBlue = ({title, fullWidth = false, type = 'button', onClick}: ButtonBluePropsT) => {
    return (
        <Button
            type={type}
            variant="contained"
            fullWidth={fullWidth}
            sx={[
                {
                    '&:hover': {
                        backgroundColor: GlobalThemeSettings.color.blue,
                        boxShadow: 'none',
                    },
                },
                {
                    boxShadow: 'none',
                    borderRadius: '15px',
                    mt: 2,
                    mb: 2,
                    p: 1.5,
                    pl: 4,
                    pr: 4,
                    backgroundColor: GlobalThemeSettings.color.blue,
                }
            ]}
            onClick={onClick}
        >
            {title}
        </Button>
    );
}

export default ButtonBlue;
