import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Divider,
} from "@mui/material";
import {
    Logout as LogoutIcon,
    Home,
} from '@mui/icons-material';
import {HeaderBlock} from "./style";
import {useEffect, useState} from "react";
import * as Pathname from "Utils";
import LanguageSwitcher from "Blocks/LanguageSwitcher/LanguageSwitcher";
import {useStore} from "stores";
import {observer} from "mobx-react-lite";

interface propsT {
    title: string;
    isChatBot?: boolean;
}

export const Header = ({title, isChatBot = false}: propsT) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const {
        userStore: {
            data: userState,
            getUser
        }
    } = useStore();

    useEffect(() => {
        if (!userState) {
            getUser();
        }
    }, []);

    const logout = () => {
        localStorage.removeItem('jwt_token');
        localStorage.clear();
        window.location.href = Pathname.login;
    };

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const setAccount = (id: number) => {
        localStorage.setItem('appId', String(id));
        window.location.href = '/chat';
    };

    return (
        <HeaderBlock>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: [1],
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        px: [1],
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                    >
                        {title}
                    </Typography>

                </Toolbar>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        px: [1],
                    }}
                >
                    <LanguageSwitcher />
                    {!isChatBot && (
                        <>
                            <IconButton
                                color="inherit"
                                onClick={handleClick}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Home />
                            </IconButton>
                            <IconButton color="inherit" onClick={logout} >
                                <LogoutIcon />
                            </IconButton>
                        </>
                    )}
                </Toolbar>

                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {userState?.myApplicationId && (
                        <MenuItem onClick={() => userState.myApplicationId && setAccount(userState.myApplicationId)}>
                            My account
                        </MenuItem>
                    )}
                    {userState?.appOperator && userState?.myApplicationId && (
                        <Divider />
                    )}
                    {userState?.appOperator.map((el) => (
                        <MenuItem onClick={() => setAccount(el.id)} key={el.id}>
                            {el.name}
                        </MenuItem>
                    ))}
                </Menu>
            </Toolbar>
        </HeaderBlock>
    );
}

export default observer(Header);