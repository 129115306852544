import React, {useEffect, useRef, useState} from 'react';
import {Send} from '@mui/icons-material';
import {
    InputName,
    InputRow,
    TextInputWrapper,
    ButtonChat,
    UsernameGroup,
} from "./style";
import {Button} from "@mui/material";
import {GlobalThemeSettings} from "Utils/GlobalThemeSettings";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {useStore} from "stores";

export const UsernameInput = observer(({token}: {token: string}) => {
    const { t } = useTranslation('common');

    const {
        chatStore: {
            chatId,
            error,
            applicationToken,
            chatCloseDevice,
            setName: setNameStore,
        },
        widgetSettingsStore: {
            settings,
        },
        deviceStore: {
            deviceToken,
            isDeviceCreate,
            setIsDeviceCreate,
            deviceCreate,
        }
    } = useStore();

    useEffect(() => {
        if (isDeviceCreate && deviceToken) {
            setIsDeviceCreate(false);
            window.parent.postMessage({
                deviceToken: deviceToken
            }, "*");
            if (Number(settings?.typeStartPage) === 3) {
                setNameStore(deviceToken);
            }

        }
    }, [deviceToken, isDeviceCreate, settings]);

    const [name, setName] = useState<string>('');

    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
    };

    const onFormSubmit = () => {
        if (name.length > 0 || Number(settings?.typeStartPage) === 3) {
            deviceCreate(token, name);
            setNameStore(name);
            setName('');
        }
    };

    return (
        <footer>
            {(Number(settings?.typeStartPage) === 2 ||
                Number(settings?.typeStartPage) === 3) ? (
                <UsernameGroup
                    backgroundColor={settings?.startPageBackground}
                >
                    {Number(settings?.typeStartPage) !== 3 && (
                        <TextInputWrapper
                            backgroundColor={settings?.footerInputBackground}
                        >
                            <InputName
                                backgroundColor={settings?.footerInputBackground}
                                color={settings?.footerInputColor}
                                placeholderColor={settings?.footerInputPlaceholderColor}
                                value={name}
                                onChange={handleChange}
                                placeholder={`${t('widget.namePlaceholder')}`}
                            />
                        </TextInputWrapper>
                    )}
                    <Button
                        type="button"
                        variant="contained"
                        fullWidth={true}
                        sx={[
                            {
                                '&:hover': {
                                    backgroundColor: settings?.startPageButtonBackground
                                        ? settings.startPageButtonBackground
                                        : GlobalThemeSettings.color.blue,
                                    color: settings?.startPageButtonColor
                                        ? settings.startPageButtonColor
                                        : GlobalThemeSettings.color.white,
                                    boxShadow: 'none',
                                },
                            },
                            {
                                boxShadow: 'none',
                                borderRadius: '15px',
                                mt: 1.5,
                                p: 1.5,
                                pl: 4,
                                pr: 4,
                                backgroundColor: settings?.startPageButtonBackground
                                    ? settings.startPageButtonBackground
                                    : GlobalThemeSettings.color.blue,
                                color: settings?.startPageButtonColor
                                    ? settings.startPageButtonColor
                                    : GlobalThemeSettings.color.white,
                            }
                        ]}
                        onClick={onFormSubmit}
                    >
                        {t('widget.startButton')}
                    </Button>
                </UsernameGroup>
            ) : (
                <InputRow
                    backgroundColor={settings?.footerBackground}
                >
                    <TextInputWrapper
                        backgroundColor={settings?.footerInputBackground}
                    >
                        <InputName
                            backgroundColor={settings?.footerInputBackground}
                            color={settings?.footerInputColor}
                            placeholderColor={settings?.footerInputPlaceholderColor}
                            value={name}
                            onChange={handleChange}
                            placeholder={`${t('widget.namePlaceholder')}`}
                            ref={inputRef as React.RefObject<HTMLInputElement>}
                        />
                    </TextInputWrapper>
                    <ButtonChat onClick={onFormSubmit}>
                        <Send />
                    </ButtonChat>
                </InputRow>
            )}
        </footer>
    );
});
export default UsernameInput;