import { createContext, useContext } from "react";
import UserStore from "./User/UserStore";
import OperatorStore from "./Operator/OperatorStore";
import ChatStore from "./Chat/ChatStore";
import RoomState from "./Room/RoomState";
import WidgetSettingsStore from "./WidgetSettings/WidgetSettingsStore";
import DeviceStore from "./Device/DeviceStore";

interface Store {
    userStore: UserStore,
    operatorStore: OperatorStore
    chatStore: ChatStore,
    roomState: RoomState,
    widgetSettingsStore: WidgetSettingsStore,
    deviceStore: DeviceStore,
}

export const store: Store = {
    userStore: new UserStore(),
    operatorStore: new OperatorStore(),
    chatStore: new ChatStore(),
    roomState: new RoomState(),
    widgetSettingsStore: new WidgetSettingsStore(),
    deviceStore: new DeviceStore(),
}

export const StoreContext = createContext(store);

export const useStore = () => {
    return useContext(StoreContext)
}
