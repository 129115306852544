import React, {useEffect, useState} from 'react';
import {
    ButtonClose,
    ButtonCloseBlock,
    ErrorBlock,
    GroupClose,
    InputColumn,
} from "./style";
import ChatInput from "./ChatInput";
import UsernameInput from "./UsernameInput";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useStore } from "stores";

export const FooterWidget = observer(() => {
    const { t } = useTranslation('common');

    const {
        chatStore: {
            chatId,
            name,
            error,
            applicationToken,
            chatCloseDevice,
        },
        widgetSettingsStore: {
            settings,
        },
        deviceStore: {
            deviceToken,
            setDeviceToken,
        }
    } = useStore();

    const [closeChat, setCloseChat] = useState(false);
    const [chatIsClose, setChatIsClose] = useState(false);

    const closeChatSend = () => {
        if (deviceToken && chatId) {
            chatCloseDevice(deviceToken, chatId);
            setCloseChat(false);
            setDeviceToken(null);
        }
    }

    return (
        <>
            {closeChat ? (
                <GroupClose
                    backgroundColor={settings?.footerBackground}
                    backgroundColorBtnCancel={settings?.backgroundColorBtnCancel}
                    backgroundColorBtnClose={settings?.backgroundColorBtnClose}
                    colorBtnCancel={settings?.colorBtnCancel}
                    colorBtnClose={settings?.colorBtnClose}
                >
                    <Button
                        type="button"
                        variant="contained"
                        className="button-close"
                        fullWidth={true}
                        sx={[
                            {
                                '&:hover': {
                                    boxShadow: 'none',
                                },
                            },
                            {
                                boxShadow: 'none',
                                borderRadius: '15px',
                                mt: 1.5,
                                p: 1.5,
                                pl: 4,
                                pr: 4,
                            }
                        ]}
                        onClick={closeChatSend}
                    >
                        {t('widget.buttonClose')}
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className="button-cancel"
                        fullWidth={true}
                        sx={[
                            {
                                '&:hover': {
                                    boxShadow: 'none',
                                },
                            },
                            {
                                boxShadow: 'none',
                                borderRadius: '15px',
                                mt: 1.5,
                                p: 1.5,
                                pl: 4,
                                pr: 4,
                            }
                        ]}
                        onClick={() => setCloseChat(false)}
                    >
                        {t('widget.buttonCancel')}
                    </Button>
                </GroupClose>
            ) : (
                <InputColumn
                    backgroundColor={
                        ((Number(settings?.typeStartPage) === 2 ||
                            Number(settings?.typeStartPage) === 3
                        ) && !name) ?
                            settings?.startPageBackground || '#f3f5f8' :
                            settings?.footerBackground
                    }
                    iconColor={settings?.footerIconColor}
                >
                    {name ? (
                        <>
                            {error && typeof error === 'string' && (
                                <ErrorBlock>
                                    {error}
                                </ErrorBlock>
                            )}
                            <ChatInput
                                name={name}
                                setChatIsClose={() => setChatIsClose(!chatIsClose)}
                            />
                            {!chatIsClose && (
                                <ButtonCloseBlock
                                    backgroundColor={settings?.footerBackground}
                                >
                                    <ButtonClose
                                        color={settings?.footerCloseTextColor}
                                        onClick={() => setCloseChat(true)}
                                    >
                                        {t('widget.closeChat')}
                                    </ButtonClose>
                                </ButtonCloseBlock>
                            )}
                        </>
                    ) : applicationToken && (
                        <UsernameInput token={applicationToken} />
                    )}
                </InputColumn>
            )}
        </>
    );
});
export default FooterWidget;