import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import io from 'socket.io-client';
import notificationFile from '../File/not.mov';
import addNotification from "react-push-notification";
import {checkLocalStorage} from "../Utils";
import {useStore} from "../stores";

export type TMessage = {
  [key: string]: any;
};

export type TMessageBody = {
  [key: string]: any;
  text: string;
};

export type SendMessageT = {
  text: string | null;
  applicationId?: number;
  deviceToken?: string;
  chatId: number;
  file: Record<string, string> | null;
};

// const SERVER_URL =
//     process.env.REACT_APP_SERVER_URL + '/chat';
const SERVER_URL = process.env.REACT_APP_SERVER_CHAT_URL || 'https://api.helpchat.faraday.uno/chat';

let applicationId: any = null;
if (checkLocalStorage()) {
  applicationId = localStorage.getItem('appId');
}


export function useSocket() {
  const socketRef = useRef<any>();
  const [deviceToken, setDeviceToken] = useState<string | null>(null);
  const [notifications, setNotifications] = useState(0);
  const [isClose, setIsClose] = useState(false);

  const {
    chatStore: {
      sendMessage: sendMessageStore,
      setNotRead,
    }
  } = useStore();

  useEffect(() => {
    socketRef.current = io(SERVER_URL, {
      transports : ['websocket'],
    });
    socketRef.current.on('joinedRoom', (item: any) => {
      console.log(item, 'room connected');
    });
    socketRef.current.on('hasLeaveRoom', (item: any) => {
      console.log(item, 'room leave Room');
    });
    socketRef.current.on('closeChat', () => {
      setIsClose(true);
    });
    socketRef.current.on('msgToClient', (message: any) => {
      if (socketRef.current
          && message.applicationId !== applicationId && message.deviceToken !== deviceToken
      ) {
        const newMessage = {
          text: message.text,
          applicationId: message.applicationId,
          user: message.deviceToken,
          file: message.file,
          createdAt: new Date(),
        };
        sendMessageStore(newMessage);
        if (deviceToken) {
          playNotificationSound();
        }
        if (!deviceToken) {
          socketRef.current.emit('readMessage', {
            id: message.id,
            applicationId,
          });
        }
      }
    });
    socketRef.current.on(
        'listeningMessages',
        ({ count, applicationId }: { count: number, applicationId: number }) => {
          if (socketRef.current) {
            if (Number(localStorage.getItem('appId')) === applicationId) {
              setNotifications(count);
            }
          }
        }
    );
    socketRef.current.on(
        'deviceNotRead',
        ({ count }: { count: number }) => {
          if (socketRef.current) {
            if (deviceToken) {
              setNotRead(count);
            }
          }
        }
    );
    socketRef.current.on(
        'soundMessages',
        (data: {newMessage: boolean, message?: string, applicationId: number, appTitle: string}) => {
          if (socketRef.current) {
            if (Number(localStorage.getItem('appId')) === data.applicationId) {
              playNotificationSound();
            }
            pushNotification(data.message, data.appTitle);
          }
        }
    );
    return () => {
      disconnect()
    }
  }, [deviceToken]);

  const pushNotification = (message?: string, titleApp?: string) => {
    addNotification({
      title: `New message from ${titleApp}`,
      message,
      duration: 4000,
      icon: 'https://nanitrobot.com/wp-content/uploads/2022/09/cropped-image_2022-09-21_18-20-43-180x180.png',
      native: true
    });
  }

  const playNotificationSound = () => {
    const audio = new Audio(notificationFile);
    audio.play().catch(function (error) {
      console.log("Chrome cannot play sound without user interaction first")});
  }

  const sendMessage = (messageBody: SendMessageT) => {
    if (socketRef.current) {
      socketRef.current.emit('exchanges', messageBody);
    }
  };

  const joinRoom = (data: {applicationId?: number, chatId: number, deviceToken?: string}) => {
    if (socketRef.current) {
      if (
          (data.chatId && data.applicationId) ||
          (data.chatId && data.deviceToken)
      ) {
        socketRef.current.emit('joinRoom', data);
      }
    }
  };

  const leaveRoom = (data: { chatId: number }) => {
    if (socketRef.current) {
      if (data.chatId) {
        socketRef.current.emit('leaveRoom', data);
      }
    }
  };

  const joinListeningMessages = (applicationId: number) => {
    if (socketRef.current) {
      socketRef.current.emit('joinListeningMessages', {
        applicationId,
      });
    }
  };

  function disconnect() {
    if (socketRef.current) socketRef.current.disconnect();
  }

  return {
    joinRoom,
    leaveRoom,
    joinListeningMessages,
    notifications,
    sendMessage,
    disconnect,
    isClose,
    setDeviceToken: (deviceToken: string) => setDeviceToken(deviceToken),
    socket: socketRef.current,
  };
}
