import styled from 'styled-components'

export const InputRow = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin: 5px;
  width: -webkit-fill-available;
  svg {
    fill: #858585;
  }
`;

export const TextAreaWrapper = styled.div`
  background: #f2f2f2;
  padding: 5px 17px 5px;
  border-radius: 18px;
  width: calc(100% - 155px);
  transition: 0.3s;
  &.record-wrapper {
    width: -webkit-fill-available;
  }
  audio {
    width: 100%;
    height: 28px;
    padding-top: 2px;
  }
`;

export const TextArea = styled.textarea`
  border: none;
  font-size: 16px;
  line-height: 150%;
  color: rgb(51, 51, 51);
  overflow-y: auto;
  overflow-wrap: break-word;
  resize: none;
  width: 100%;
  max-height: 115px;
  background: #f2f2f2;
  outline: none;
  //min-height: 28px;
`;

export const RecordTime = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: rgb(51, 51, 51);
  min-height: 28px;
`;

export const EmojiWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  border: 1px solid rgb(239, 239, 239);
  border-radius: 5px;
  box-shadow: rgb(239 239 239) 0px 5px 10px;
`;


export const ButtonChat = styled.button`
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  &:hover {
    background-color: rgb(128,128,128, 0.1);
  }
`
