interface ErrorStatusT {
    status: boolean,
    message: string,
}

interface ErrorT {
    [key: string]: ErrorStatusT;
}

export const ValidateForm = (form: FormData, lang?: string) => {
    let error: ErrorT = {};
    let isError = false;
    form.forEach((el, index) => {
        if (typeof el === 'string' && el.trim().length <= 0) {
            error[index] = {
                status: true,
                message: lang === 'ua' ? 'Заповніть поле' : 'Field is empty'
            };
            isError = true;
        } else {
            if (
                index === 'email' &&
                typeof el === 'string' &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(el)
            ) {
                error[index] = {
                    status: true,
                    message: lang === 'ua' ? 'Неправильна адреса електронної пошти' : 'Incorrect email address'
                };
                isError = true;
            } else {
                error[index] = {
                    status: false,
                    message: ''
                };
            }
        }
    })
    return { error, isError };
}
export default ValidateForm;