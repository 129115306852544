import { makeAutoObservable, runInAction } from 'mobx';
import { ApiServicePromise } from "Utils/ApiService";

type ErrorStatusT = {
    status: boolean,
    message: string,
}
type ErrorT = {
    [key: string]: ErrorStatusT;
}

export default class WidgetSettingsStore {
    isLoading = true;
    name: string = '';
    settings: Record<string, string> | null = null;
    status: string = '';
    error: ErrorT | null = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setSettings(settings: Record<string, string>) {
        this.settings = settings;
    }

    getSettings(token: string) {
        ApiServicePromise({
            endPoint: `application/settings/${token}`,
            method: 'GET',
        }).then((res) => {
            runInAction(() => {
                this.name = res.name;
                this.settings = res.settings;
                this.isLoading = false;
            });
        });
    }
}
