import { makeAutoObservable, runInAction } from 'mobx';
import {ApiService, ApiServicePromise} from "Utils/ApiService";

type ErrorStatusT = {
    status: boolean,
    message: string,
}
type ErrorT = {
    [key: string]: ErrorStatusT;
}

type MessageT = {
    id?: number;
    user?: string | null;
    deviceToken?: string | null;
    applicationId?: number;
    text?: string | null;
    createdAt: Date;
    file?: Record<string, string> | null;
}

export default class ChatStore {
    isLoading = true;
    isDeviceRemove = false;
    messages: MessageT[] = [];
    applicationToken?: string;
    chatId?: number | null;
    status?: string;
    name?: string | null;
    newMessageCount = 0;
    notRead = 0;
    error?: ErrorT | string;

    lastAbortController: any;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    sendMessage(message: MessageT) {
        this.messages = [
            ...this.messages,
            message
        ];
    }

    setName(name: string) {
        this.name = name;
    }

    clearMessages() {
        this.messages = [];
    }

    setNotRead(count: number) {
        this.notRead = count;
    }

    setApplicationToken(applicationToken: string) {
        this.applicationToken = applicationToken;
    }

    setIsDeviceRemove(isDeviceRemove: boolean) {
        this.isDeviceRemove = isDeviceRemove;
    }

    setNewMessageCount(count: number) {
        this.newMessageCount = count;
    }

    setError(error: ErrorT | string) {
        this.error = error;
    }

    setChatId(chatId?: number | null) {
        this.chatId = chatId;
    }

    clearChatStore() {
        this.chatId = null;
        this.name = null;
        this.notRead = 0;
        this.messages = [];
        this.isDeviceRemove = true;
    }

    getChatApplication(id: string) {
        this.isLoading = true;

        if (this.lastAbortController) {
            this.lastAbortController.abort();
        }

        const abortController = new AbortController();
        this.lastAbortController = abortController;

        ApiServicePromise({
            endPoint: `chat/get-chat-application/${id}/1`,
            method: 'GET',
            headers: {'application': localStorage.getItem('appId')},
            signal: abortController.signal,
        }).then((res) => {
            if (abortController.signal.aborted) {
                return new Promise(() => {});
            }
            runInAction(() => {
                this.messages = res.messages;
                this.chatId = res.chatId;
            });
            this.isLoading = false;
        });
    }

    getChatDevice(token: string, deviceToken: string) {
        this.isLoading = true;

        if (this.lastAbortController) {
            this.lastAbortController.abort();
        }

        const abortController = new AbortController();
        this.lastAbortController = abortController;

        ApiServicePromise({
            endPoint: `chat/get-chat/1?deviceToken=${deviceToken}&applicationToken=${token}`,
            method: 'GET',
            signal: abortController.signal,
        }).then((res) => {
            if (abortController.signal.aborted) {
                return new Promise(() => {});
            }
            runInAction(() => {
                if (res.deviceRemove) {
                    this.isDeviceRemove = true;
                } else {
                    this.name = res.deviceName || deviceToken;
                    this.chatId = res.chatId;
                    this.messages = res.messages;
                    this.notRead = res.notRead;
                }
            });
            this.isLoading = false;
        });
    }

    chatRead(chatId: number) {
        ApiServicePromise({
            endPoint: `chat/read/${chatId}`,
            method: 'PUT',
        }).then((res) => {
            runInAction(() => {
                this.notRead = 0;
                this.isLoading = false;
            });
        });
    }

    chatCloseDevice(deviceToken: string, chatId: number) {
        ApiServicePromise({
            endPoint: 'chat/close',
            method: 'POST',
            body: {
                userToken: deviceToken,
                roomId: chatId,
            }
        }).then((res) => {
            this.clearChatStore()
        });
    }

    createChat(
        applicationToken: string,
        deviceToken: string,
        text?: string | null,
        file?: string | null,
    ) {
        ApiServicePromise({
            endPoint: 'chat/create-chat',
            method: 'POST',
            body: {
                applicationToken: applicationToken,
                deviceToken: deviceToken,
                text: file ? null : text,
                file: file ? file : null,
            }
        }).then((res) => {
            runInAction(() => {
                this.chatId = res.id;
            });
        });
    }
}
