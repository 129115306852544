import React from 'react';
import { InputColumn } from "./style";
import ChatInputSettings from "./ChatInputSettings";
import UsernameInputSettings from "./UsernameInputSettings";
import {
    ButtonClose,
    ButtonCloseBlock,
    GroupClose,
} from "../FooterWidget/style";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

interface FooterWidgetT {
    chat?: boolean;
    closeChat?: boolean;
    backgroundColor: string;
    inputColor: string;
    inputBackground: string;
    iconColor: string;
    inputInputPlaceholderColor: string;
    typeTwoStartPage?: boolean;
    typeTreeStartPage?: boolean;
    startPageBackground?: string;
    startPageButtonColor?: string;
    startPageButtonBackground?: string;
    footerCloseTextColor?: string;
    backgroundColorBtnCancel?: string;
    backgroundColorBtnClose?: string;
    colorBtnCancel?: string;
    colorBtnClose?: string;
}

export const FooterWidgetSettings =
    ({
         chat,
         backgroundColor,
         inputColor,
         inputBackground,
         iconColor,
         inputInputPlaceholderColor,
         typeTwoStartPage,
         typeTreeStartPage,
         startPageBackground,
         startPageButtonColor,
         startPageButtonBackground,
         footerCloseTextColor,
         closeChat,
         backgroundColorBtnCancel,
         backgroundColorBtnClose,
         colorBtnCancel,
         colorBtnClose,
    }: FooterWidgetT
    ) => {
        const { t } = useTranslation('common');
        return (
        <>
            {closeChat ? (
                <GroupClose
                    backgroundColor={backgroundColor}
                    backgroundColorBtnCancel={backgroundColorBtnCancel}
                    backgroundColorBtnClose={backgroundColorBtnClose}
                    colorBtnCancel={colorBtnCancel}
                    colorBtnClose={colorBtnClose}
                >
                    <Button
                        type="button"
                        variant="contained"
                        className="button-close"
                        fullWidth={true}
                        sx={[
                            {
                                '&:hover': {
                                    boxShadow: 'none',
                                },
                            },
                            {
                                boxShadow: 'none',
                                borderRadius: '15px',
                                mt: 1.5,
                                p: 1.5,
                                pl: 4,
                                pr: 4,
                            }
                        ]}
                    >
                        {t('widget.buttonClose')}
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className="button-cancel"
                        fullWidth={true}
                        sx={[
                            {
                                '&:hover': {
                                    boxShadow: 'none',
                                },
                            },
                            {
                                boxShadow: 'none',
                                borderRadius: '15px',
                                mt: 1.5,
                                p: 1.5,
                                pl: 4,
                                pr: 4,
                            }
                        ]}
                    >
                        {t('widget.buttonCancel')}
                    </Button>
                </GroupClose>
            ) : (
                <InputColumn
                    backgroundColor={(typeTwoStartPage || typeTreeStartPage) ? startPageBackground || '#f3f5f8' : backgroundColor}
                    iconColor={iconColor}
                >
                    {chat ? (
                        <>
                            <ChatInputSettings
                                backgroundColor={backgroundColor}
                                inputColor={inputColor}
                                inputBackground={inputBackground}
                                inputInputPlaceholderColor={inputInputPlaceholderColor}
                            />
                            <ButtonCloseBlock
                                backgroundColor={backgroundColor}
                            >
                                <ButtonClose
                                    color={footerCloseTextColor}
                                >
                                    {t('widget.closeChat')}
                                </ButtonClose>
                            </ButtonCloseBlock>
                        </>
                    ) : (
                        <UsernameInputSettings
                            backgroundColor={backgroundColor}
                            inputColor={inputColor}
                            inputBackground={inputBackground}
                            inputInputPlaceholderColor={inputInputPlaceholderColor}
                            typeTwoStartPage={typeTwoStartPage}
                            typeTreeStartPage={typeTreeStartPage}
                            startPageBackground={startPageBackground}
                            startPageButtonColor={startPageButtonColor}
                            startPageButtonBackground={startPageButtonBackground}
                        />
                    )}
                </InputColumn>
            )}
        </>
    );
};
export default FooterWidgetSettings;