import * as React from 'react';
import {LangBlock} from "./style";
import {useTranslation} from "react-i18next";

export const LanguageSwitcher = () => {
    const { i18n } = useTranslation()

    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
    }

    return (
        <LangBlock>
            <button
                className={i18n.language === 'ua' ? 'active' : ''}
                onClick={() => changeLanguageHandler('ua')}
            >
                UA
            </button>
            <button
                className={i18n.language === 'en' ? 'active' : ''}
                onClick={() => changeLanguageHandler('en')}
            >
                EN
            </button>
        </LangBlock>
    );
}

export default LanguageSwitcher;