import React from 'react';
import {
    Grid,
} from '@mui/material';
import {
    UsersArea,
} from "./style";
import {useParams} from "react-router";
import {OperatorsMenu} from "Components/OperatorsMenu/OperatorsMenu";
import FormOperator from "./Components/FormOperator";
import MainWrapper from "Components/MainWrapper/MainWrapper";

export const Users = () => {
    const { id } = useParams();
    return (
        <>
            <Grid item xs={3} sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <OperatorsMenu id={id ? Number(id) : undefined} />
            </Grid>
            <Grid item xs={9}>
                <UsersArea>
                    <FormOperator id={id ? Number(id) : undefined} />
                </UsersArea>
            </Grid>
        </>
    );
}

export default Users;
