import {RefObject, useEffect, useState} from "react";

export default function useOutsideClicker(
    ref: RefObject<HTMLElement>,
    disableClassName?: string
) {
    const [show, setShow] = useState<boolean>(false);
    function handleClickOutside(event: MouseEvent) {
        event.stopPropagation();

        const target = event.target as Element;
        let checkClassParent, checkClass = null;
        if (disableClassName) {
            checkClassParent = target.closest(`.${disableClassName}`);
            checkClass = target.classList.contains(disableClassName);
        }

        if (ref?.current && !ref.current.contains(event.target as Node) && !(checkClassParent || checkClass)) {
            setShow(false);
        }
    }
    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
    return { show, setShow, ref };
}