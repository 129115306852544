import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ChatIcon from '@mui/icons-material/Chat';
import TelegramIcon from '@mui/icons-material/Telegram';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
    CountWidgetButton,
    ModalChat,
    WidgetBlock,
    WidgetButton,
    WidgetButtonTypeTwo, WidgetContent,
} from './style'
import HeaderWidget from "../Components/HeaderWidget/HeaderWidget";
import FooterWidget from "../Components/FooterWidget/FooterWidget";
import ChatWidget from "../Components/ChatWidget/ChatWidget";
import './style.css'
import { useTranslation } from "react-i18next";
import { useStore } from "../stores";
import { observer } from "mobx-react-lite";
import notificationFile from "../File/not.mov";
import {Tooltip} from "@mui/material";

const getQuery = () => {
    return new URLSearchParams(window.location.search);
}

export const Widget = observer(() => {
    const { i18n } = useTranslation()
    const { t } = useTranslation('common');
    const { token } = useParams();

    const {
        chatStore: {
            notRead,
            chatId,
            chatRead,
            isDeviceRemove,
            getChatDevice,
            setIsDeviceRemove,
            setApplicationToken,
        },
        widgetSettingsStore: {
            name,
            settings,
            getSettings,
            isLoading: isLoadingWidget,
        },
        deviceStore: {
            setDeviceToken,
        }
    } = useStore();

    const lang = getQuery().get('lang');

    const [open, setOpen] = useState<boolean>(false);
    const [openContent, setOpenContent] = useState(false);

    const [viewLabel, setViewLabel] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            window.parent.postMessage({
                viewLabel: true,
                width: lang === 'en' ? 200 : 250,
            }, "*")
            setViewLabel(true)
            const audio = new Audio(notificationFile);
            audio.play().catch(function (error) {
                console.log("Chrome cannot play sound without user interaction first")});
            setTimeout(() => {
                const block = document.querySelector('.animation');
                if (block) {
                    block.classList.remove("animation");
                }
            }, 500)
        }, 15000)
    }, [])

    useEffect(() => {
        if (isDeviceRemove) {
            window.history.pushState({}, document.title, window.location.pathname);
            window.parent.postMessage({
                deviceTokenRemove: true
            }, "*");
            setIsDeviceRemove(false);
        }
    }, [isDeviceRemove]);

    useEffect(() => {
        if (token) {
            setApplicationToken(token);
            getSettings(token);
        }
        const deviceToken = getQuery().get('device');
        if (deviceToken && token) {
            setDeviceToken(deviceToken)
            getChatDevice(token, deviceToken)
        }
    }, []);

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [i18n]);

    const openHandle = () => {
        setOpen(!open);
        if (openContent) {
            setOpenContent(false);
        }
        if (chatId && notRead > 0) {
            chatRead(chatId)
        }
        window.parent.postMessage({
            openModal: !open
        }, "*");
    }

    const openContentHandle = () => {
        setOpenContent(!openContent);
        let time = 0;
        if (!(!openContent)) {
            time = 500;
        }
        setTimeout(() => {
            window.parent.postMessage({
                openSocialLinks: !openContent
            }, "*");
        }, time);
    }


    return !isLoadingWidget ? (
        <WidgetBlock>
            <ModalChat className={`${open && 'active'}`}>
                <HeaderWidget
                    setOpen={openHandle}
                    title={name}
                    backgroundColor={settings?.headerBackground}
                    color={settings?.headerColor}
                />
                <ChatWidget />
                <FooterWidget />
            </ModalChat>
            {settings?.useSocialLinks && (
                <WidgetContent className={`${openContent ? 'active' : ''}`}>
                    <Tooltip
                        title="Chat"
                        placement="left"
                    >
                        <WidgetButton
                            className={`btn-item`}
                            onClick={openHandle}
                            backgroundColor="#38C4B1"
                            color="#FFFFFF"
                        >
                            <QuestionAnswerIcon />
                        </WidgetButton>
                    </Tooltip>
                    {settings?.telegramLink && (
                        <Tooltip
                            title="Telegram"
                            placement="left"
                        >
                            <WidgetButton
                                className={`btn-item`}
                                onClick={() => {
                                    window.open(settings.telegramLink, '_blank');
                                }}
                                backgroundColor="#089be5"
                                color="#FFFFFF"
                            >
                                <TelegramIcon />
                            </WidgetButton>
                        </Tooltip>
                    )}
                    {settings?.viberLink && (
                        <Tooltip
                            title="Viber"
                            placement="left"
                        >
                            <WidgetButton
                                className={`btn-item`}
                                onClick={() => {
                                    window.open(settings.viberLink, '_blank');
                                }}
                                backgroundColor="#7360f2"
                                color="#FFFFFF"
                            >
                                <img src="/assets/icon_new-white.svg" alt="viber" width="25" />
                            </WidgetButton>
                        </Tooltip>
                    )}
                </WidgetContent>
            )}

            {Number(settings?.typeButton) === 2 ? (
                <WidgetButtonTypeTwo
                    backgroundColor={settings?.startButtonBackground}
                    color={settings?.startButtonColor}

                    className={`modal-button ${!open && 'active'}`}
                    onClick={settings?.useSocialLinks ? openContentHandle : openHandle}
                >
                    <div className="label">
                        {t('widget.openButton')}
                    </div>
                    <WidgetButton
                        className={`active widget-type-two`}
                        onClick={settings?.useSocialLinks ? openContentHandle : openHandle}
                        backgroundColor={settings?.startButtonBackground}
                        color={settings?.startButtonColor}
                    >
                        <ChatIcon />
                        {notRead > 0 && (
                            <CountWidgetButton
                                backgroundColor={settings?.startButtonBackground}
                                color={settings?.startButtonColor}
                            >
                                {notRead}
                            </CountWidgetButton>
                        )}
                    </WidgetButton>
                </WidgetButtonTypeTwo>
            ) : (
                <>
                    <WidgetButton
                        className={`modal-button ${!open && 'active'}`}
                        onClick={settings?.useSocialLinks ? openContentHandle : openHandle}
                        backgroundColor={settings?.startButtonBackground}
                        color={settings?.startButtonColor}
                    >
                        <div className={`label ${viewLabel && 'active animation'}`}>
                            {t('widget.openButton')}
                        </div>
                        <ChatIcon />
                        {notRead > 0 && (
                            <CountWidgetButton
                                backgroundColor={settings?.startButtonBackground}
                                color={settings?.startButtonColor}
                            >
                                {notRead}
                            </CountWidgetButton>
                        )}
                    </WidgetButton>
                </>
            )}
        </WidgetBlock>
    ) : (<></>);
});
export default Widget;
