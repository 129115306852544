import styled from 'styled-components'
import {GlobalThemeSettings} from "../Utils/GlobalThemeSettings";

type ColorT = {
    backgroundColor?: string;
    color?: string;
}

export const WidgetBlock = styled.div`
  position: relative;
  border-radius: 5px 5px 0 0;
`

export const ModalChat = styled.div`
  visibility: hidden;
  opacity: 0;
  height: 0;
  &.active {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, visibility 0.3s;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
`
export const WidgetButton = styled.button<ColorT>`
  position: fixed;
  right: 0;
  bottom: 0;
  border: 0;
  //background-color: #FEE600;
  width: 60px;
  height: 60px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
  color: ${props => props.color || '#FFFFFF'};
  &.active {
    display: flex;
  }
  &.widget-type-two {
    position: static;
  }
  svg {
    position: relative;
    z-index: 9999;
  }

  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }


  .label {
    display: none;
    
    &.active {
      display: flex;
    }
    &.animation {
      animation: slide-up 0.5s ease-in-out;
    }
    
    position: absolute;
    right: 55px;
    align-items: center;
    height: 44px;
    padding: 0 13px;
    margin-right: 13px;
    border-radius: 25px;
    background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
    color: ${props => props.color || GlobalThemeSettings.color.white};
    box-shadow: 0 1px 6px rgb(0 0 0%);
    white-space: nowrap;
    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      z-index: 11;
      top: 13px;
      right: -8px;
      border-width: 10px 6px;
      border-style: solid;
      border-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }

`

export const WidgetContent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    .btn-item {
        display: flex;
        transition: 0.4s;
        opacity: 0;
    }

    &.active {
        .btn-item {
            visibility: visible;
            opacity: 1;
        }

        .btn-item:first-child {
            -webkit-transform: translateY(-70px);
            -ms-transform: translateY(-70px);
            transform: translateY(-70px)
        }

        .btn-item:nth-child(2) {
            -webkit-transform: translateY(-140px);
            -ms-transform: translateY(-140px);
            transform: translateY(-140px)
        }

        .btn-item:nth-child(3) {
            -webkit-transform: translateY(-210px);
            -ms-transform: translateY(-210px);
            transform: translateY(-210px)
        }

        .btn-item:nth-child(4) {
            -webkit-transform: translateY(-280px);
            -ms-transform: translateY(-280px);
            transform: translateY(-280px)
        }

        .btn-item:nth-child(5) {
            -webkit-transform: translateY(-350px);
            -ms-transform: translateY(-350px);
            transform: translateY(-350px)
        }

        .btn-item:nth-child(6) {
            -webkit-transform: translateY(-420px);
            -ms-transform: translateY(-420px);
            transform: translateY(-420px)
        }

        .btn-item:nth-child(7) {
            -webkit-transform: translateY(-490px);
            -ms-transform: translateY(-490px);
            transform: translateY(-490px)
        }

        .btn-item:nth-child(8) {
            -webkit-transform: translateY(-560px);
            -ms-transform: translateY(-560px);
            transform: translateY(-560px)
        }

        .btn-item:nth-child(9) {
            -webkit-transform: translateY(-630px);
            -ms-transform: translateY(-630px);
            transform: translateY(-630px)
        }

        .btn-item:nth-child(10) {
            -webkit-transform: translateY(-700px);
            -ms-transform: translateY(-700px);
            transform: translateY(-700px)
        }
    }
`

export const CountWidgetButton = styled.div<ColorT>`
  position: absolute;
  right: -2px;
  top: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
  color: ${props => props.color || '#FFFFFF'};
  border: 2px solid white;
  min-width: 20px;
  height: 20px;
`

export const WidgetButtonTypeTwo = styled.div<ColorT>`
  display: none;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  cursor: pointer;
  .label {
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 13px;
    margin-right: 13px;
    border-radius: 25px;
    background-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
    color: ${props => props.color || GlobalThemeSettings.color.white};
    box-shadow: 0 1px 6px rgb(0 0 0%);
    white-space: nowrap;
    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      z-index: 11;
      top: 13px;
      right: -8px;
      border-width: 10px 6px;
      border-style: solid;
      border-color: ${props => props.backgroundColor || GlobalThemeSettings.color.blue};
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }

  &.active {
    display: flex;
  }
`;
