import React, {useEffect, useRef, useState} from 'react';
import {Send, AttachFile, Close, Check, Mic} from '@mui/icons-material';
import {
    EmojiWrapper,
    InputRow,
    TextArea,
    TextAreaWrapper,
    ButtonChat,
    ChatIsCloseBlock
} from "./style";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import useOutsideClicker from "Hooks/useOutsideClicker";
import {useSocket} from "Hooks/useSocket";
import axios from "axios";
import { Button } from "@mui/material";
import { GlobalThemeSettings } from "Utils/GlobalThemeSettings";
import { useTranslation } from "react-i18next";
import useRecorder from "Hooks/useRecorder";
import { RecordTime } from "../MessageTextEditorSimple/style";
import { observer } from "mobx-react-lite";
import { useStore } from "stores";

export const ChatInput = observer(({name, setChatIsClose}: {name: string, setChatIsClose: () => void}) => {
    const { t } = useTranslation('common');

    const {
        chatStore: {
            chatId,
            setError,
            applicationToken,
            clearChatStore,
            createChat,
            sendMessage: sendMessageStore,
        },
        widgetSettingsStore: {
            settings,
        },
        deviceStore: {
            deviceToken,
            setDeviceToken: setDeviceTokenApi,
        }
    } = useStore();

    const [text, setText] = useState<string>('');
    const [deviceTokenConnect, setDeviceTokenConnect] = useState(false);

    const emojiRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const {
        sendMessage,
        joinRoom,
        setDeviceToken,
        disconnect,
        isClose,
    } = useSocket();

    const { show: show, setShow: setShow } = useOutsideClicker(emojiRef, 'emoji-btn');

    useEffect(() => {
        if (deviceToken && !deviceTokenConnect) {
            setDeviceTokenConnect(true)
            setDeviceToken(deviceToken);
        }
        if (deviceToken && chatId) {
            joinRoom({
                deviceToken: deviceToken,
                chatId: chatId,
            })
        }
        if (deviceTokenConnect && !chatId && !deviceToken) {
            disconnect();
        }
    }, [deviceToken, chatId, deviceTokenConnect]);

    useEffect(() => {
        if (isClose) {
            setChatIsClose();
        }
    }, [isClose]);

    const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setText(e.currentTarget.value);
    };

    const onFormSubmit = (file = null) => {
        if (name && (text.trim() || file)) {
            if (!chatId && applicationToken && deviceToken) {
                createChat(applicationToken, deviceToken, text, file);
            } else {
                if (deviceToken && chatId) {
                    const messageObject = {
                        deviceToken: deviceToken,
                        chatId: chatId,
                        text: file ? null : text,
                        file: file ? file : null,
                    };
                    sendMessage(messageObject);
                }
            }
            const messageObject = {
                user: deviceToken,
                createdAt: new Date(),
                text: file ? null : text,
                file: file ? file : null,
            };
            sendMessageStore(messageObject);
            if (!file) {
                setText('');
                if (inputRef.current) {
                    inputRef.current.style.height = 'auto';
                }
            }
        }
    };

    const formatBytes = (bytes: number, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const sendFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.currentTarget;

        if (!input.files?.length) {
            return;
        }

        if(input.files[0].size > (1024 * 1024 * 10)){
            setError(t('widget.errorFile') as string);
            setInterval(() => {
                setError('');
            }, 30000)
        }

        const data = new FormData()
        data.append('file', input.files[0])

        axios.post(`${process.env.REACT_APP_SERVER_URL}/file/upload`, data)
            .then((res) => onFormSubmit(res.data))
            .catch((err) => {
                let errorText = '';

                if (err.response.data.message.includes("Validation failed (expected size is less than")) {
                    const bytes = err.response.data.message.replace ( /[^\d.]/g, '' );
                    errorText = `${t('widget.errorFileSimple')} ${formatBytes(Number(bytes))}`;
                }

                if (err.response.data.message.includes("Validation failed (expected type is .")) {
                    errorText = t('widget.errorFileType');
                }

                setError(errorText as string);
                setInterval(() => {
                    setError('');
                }, 30000)
            });

        e.target.value = '';
    }

    const clearChat = () => {
        clearChatStore()
        setDeviceTokenApi(null);
        window.history.pushState({}, document.title, window.location.pathname);
        window.parent.postMessage({
            deviceTokenRemove: true
        }, "*")
        setChatIsClose();
    };

    const { recorderState, ...handlers } = useRecorder();
    const { audio, audioBlob, recordingMinutes, recordingSeconds, initRecording } = recorderState;

    const sendAudio = () => {
        if (audioBlob) {
            const data = new FormData()
            data.append('file', audioBlob)

            axios.post(`${process.env.REACT_APP_SERVER_URL}/file/upload`, data)
                .then((res) => {
                    onFormSubmit(res.data);
                    handlers.cancelRecording();
                })
                .catch((err) => {
                    let errorText = '';

                    if (err.response.data.message.includes("Validation failed (expected size is less than")) {
                        const bytes = err.response.data.message.replace ( /[^\d.]/g, '' );
                        errorText = `${t('chat.errorMaxSizeFile')} ${formatBytes(Number(bytes))}`
                    }

                    if (err.response.data.message.includes("Validation failed (expected type is .")) {
                        errorText = `${t('chat.errorTypeFile')}`
                    }

                    setError(errorText as string);
                    setInterval(() => {
                        setError('')
                    }, 30000)
                });
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            onFormSubmit(null);
        }
    }

    return (
        <InputRow
            backgroundColor={settings?.footerBackground}
            iconColor={settings?.footerIconColor}
        >
            {isClose ? (
                <ChatIsCloseBlock>
                    <p>{t('widget.chatIsClose')}</p>
                    <Button
                        type="button"
                        variant="contained"
                        fullWidth={true}
                        sx={[
                            {
                                '&:hover': {
                                    backgroundColor: settings?.startPageButtonBackground
                                        ? settings.startPageButtonBackground
                                        : GlobalThemeSettings.color.blue,
                                    color: settings?.startPageButtonColor
                                        ? settings.startPageButtonColor
                                        : GlobalThemeSettings.color.white,
                                    boxShadow: 'none',
                                },
                            },
                            {
                                boxShadow: 'none',
                                borderRadius: '15px',
                                mt: 1.5,
                                p: 1.5,
                                pl: 4,
                                pr: 4,
                                backgroundColor: settings?.startPageButtonBackground
                                    ? settings.startPageButtonBackground
                                    : GlobalThemeSettings.color.blue,
                                color: settings?.startPageButtonColor
                                    ? settings.startPageButtonColor
                                    : GlobalThemeSettings.color.white,
                            }
                        ]}
                        onClick={clearChat}
                    >
                        {t('widget.buttonClose')}
                    </Button>
                </ChatIsCloseBlock>
            ) : (
                <>
                    {show && (
                        <EmojiWrapper ref={emojiRef}>
                            <Picker
                                data={data}
                                onEmojiSelect={(e: any) => setText(text + e.native)}
                            />
                        </EmojiWrapper>
                    )}
                    {(!initRecording && !audio) && (
                        <ButtonChat>
                            <input
                                type="file"
                                id="file"
                                style={{
                                    display: 'none',
                                }}
                                accept="image/png, image/jpeg, video/mp4, video/quicktime"
                                onChange={sendFile}
                            />
                            <label htmlFor="file">
                                <AttachFile />
                            </label>
                        </ButtonChat>
                    )}
                    <TextAreaWrapper
                        backgroundColor={settings?.footerInputBackground}
                        className={(initRecording || audio) ? 'record-wrapper' : ''}
                    >
                        {initRecording ? (
                            <RecordTime>
                                <span>{recordingMinutes < 10 ? `0${recordingMinutes}` : `${recordingMinutes}`}</span>
                                <span>:</span>
                                <span>{recordingSeconds < 10 ? `0${recordingSeconds}` : `${recordingSeconds}`}</span>
                            </RecordTime>
                        ) : audio ? (
                            <audio controls src={audio} />
                        ) : (
                            <TextArea
                                backgroundColor={settings?.footerInputBackground}
                                color={settings?.footerInputColor}
                                placeholderColor={settings?.footerInputPlaceholderColor}
                                onInput={function (e) {
                                    if (inputRef.current) {
                                        inputRef.current.style.height = 'auto';
                                        if (inputRef.current.value.length > 0) {
                                            inputRef.current.style.height =
                                                inputRef.current.scrollHeight + 'px';
                                        }
                                    }
                                }}
                                value={text}
                                onChange={handleChange}
                                rows={1}
                                placeholder={`${t('widget.messagePlaceholder')}`}
                                maxLength={10000}
                                ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                                onKeyDown={handleKeyPress}
                            />
                        )}
                    </TextAreaWrapper>

                    {(initRecording || audio) ? (
                        <>
                            <ButtonChat onClick={() => handlers.cancelRecording()}>
                                <Close />
                            </ButtonChat>
                            {audio ? (
                                <ButtonChat onClick={() => {
                                    sendAudio();
                                }}>
                                    <Send />
                                </ButtonChat>
                            ) : (
                                <ButtonChat onClick={() => {
                                    handlers.saveRecording();
                                }}>
                                    <Check />
                                </ButtonChat>
                            )}
                        </>
                    ) : (
                        <>
                            <ButtonChat
                                onClick={() => {
                                    setShow(!show)
                                }}
                                className="emoji-btn"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="react-input-emoji--button--icon"
                                >
                                    {/* eslint-disable-next-line max-len */}
                                    <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10" />
                                    {/* eslint-disable-next-line max-len */}
                                    <path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0" />
                                </svg>
                            </ButtonChat>
                            <ButtonChat onClick={() => handlers.startRecording()}>
                                <Mic />
                            </ButtonChat>
                            <ButtonChat onClick={() => onFormSubmit(null)}>
                                <Send />
                            </ButtonChat>
                        </>
                    )}

                </>
            )}
        </InputRow>
    );
});
export default ChatInput;